import { useTransition } from 'react';
import { css, stack } from '@bts-web/utils-style-engine';
import { ModalComponent } from '@bts-web/utils-context';
import { AlertBox } from '../../../../common/components/AlertBox/AlertBox';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { TransferWarningHeaderBox } from '../../Reusable/TransferWarningHeaderBox/TransferWarningHeaderBox';
import { TransferWarningInformation } from './TransferWarningInformation';
import { ButtonBase } from '../../../../common/components/ButtonBase/ButtonBase';
import { DialogBase } from '../../../../common/components/DialogBase/DialogBase';

interface TransferWarningModalProps {
  translations: TCryptoTransferTranslations;
  onSubmit: () => void;
}

const TransferWarningModal: ModalComponent<TransferWarningModalProps> = ({
  translations,
  onClose,
  onSubmit,
}) => {
  const [isLoading, setTransition] = useTransition();

  return (
    <DialogBase
      id="transfer-warning-modal"
      open
      onOpenChange={onClose}
      size="small"
      visual="secondary"
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'small',
          flex: 1,
        })}
      >
        <TransferWarningHeaderBox
          title={translations.cryptoTransferWarningTitle}
          iconName="eye"
        />

        <TransferWarningInformation translations={translations} />

        <AlertBox
          variant="warning"
          className={css({ marginTop: 'extra_small_2' })}
        >
          {translations.cryptoWithdrawalLossWarning}
        </AlertBox>

        <div
          className={stack({
            gap: 'small',
            pt: 'medium',
            alignItems: 'center',
          })}
        >
          <ButtonBase
            visual={'accent'}
            isLoading={isLoading}
            onClick={() => {
              setTransition(() => {
                onSubmit();
              });
            }}
          >
            {translations.iUnderstand}
          </ButtonBase>

          <ButtonBase visual="ghost" onClick={onClose}>
            {translations.cryptoWithdrawalCtaCancel}
          </ButtonBase>
        </div>
      </div>
    </DialogBase>
  );
};

export { TransferWarningModal };
