import { FC } from 'react';
import { intlFiatValueFormatting } from '@bts-web/utils-formatting';
import {
  TAssetForTransferArray,
  TransfersSectionVariants,
} from '../../../types';
import { TransferEntityPickerEntry } from '../TransferEntityPickerEntry/TransferEntityPickerEntry';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { valueFromAssetToFiat } from '../../../../common';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { getAppConfig } from '@bts-web/core-app-config';
import { getLocale, i18n, Language } from '@bts-web/utils-lokalise';

export interface INetworkPickerListProps {
  onNetworkSelected: (
    network: NonNullable<
      NonNullable<
        NonNullable<TAssetForTransferArray[0]['networkInformation']>['networks']
      >[0]
    >,
  ) => void;
  networkList: NonNullable<
    NonNullable<
      TAssetForTransferArray[number]['networkInformation']
    >['networks']
  >;
  selectedNetworkId?: string;
  selectedAssetData: TTransfersNextStepStateWithActions['selectedAssetData'];
  variant: TransfersSectionVariants;
  translations: TCryptoTransferTranslations;
}

const NetworkPickerList: FC<INetworkPickerListProps> = ({
  onNetworkSelected,
  networkList,
  selectedNetworkId,
  selectedAssetData,
  variant,
  translations,
}) => {
  const { currency } = getAppConfig();

  const locale = getLocale(i18n.resolvedLanguage as Language);

  return (
    <ul aria-label={`network selection list`}>
      {networkList.map((network) => {
        if (!network) return null;

        const isDeposit = variant === TransfersSectionVariants.Deposit;

        const assetValueByVariant = isDeposit
          ? network?.smallDepositFee?.value
          : network?.minWithdrawalFee?.value;

        let endInfoMain;

        if (assetValueByVariant) {
          const fiatAmount = valueFromAssetToFiat(
            assetValueByVariant,
            selectedAssetData?.price,
          );

          endInfoMain = fiatAmount?.value
            ? `${intlFiatValueFormatting(Number(fiatAmount?.value), {
                locale,
                currency,
                fractionDigits: fiatAmount.precision,
              })} ${translations.fees}`
            : '';
        }

        return (
          <TransferEntityPickerEntry
            withinList
            title={network.name ?? ''}
            type="entryWithActiveState"
            active={selectedNetworkId === network.id}
            imageUrl={network.logoUrl ?? undefined}
            onPick={() => onNetworkSelected(network)}
            key={network.id}
            endInfoMain={endInfoMain}
          />
        );
      })}
    </ul>
  );
};

export { NetworkPickerList };
