/*
 * CREATE OFFER - CRYPTO TRANSFERS - WITHDRAWAL
 */

import { css } from '@bts-web/utils-style-engine';
import {
  getLocale,
  i18n,
  Language,
  useClientTranslation,
} from '@bts-web/utils-lokalise';
import { AmountFor } from '@bts-web/data-layer/server';
import { getPrecisionFloatValue } from '@bts-web/utils-formatting';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import {
  AlertBox,
  ButtonBase,
  AmountInput,
  AmountInputWrapper,
  InputError,
  getAmountInputPlaceHolder,
  DisplaySwitchWrapper,
  TradeAssetInfoDisplay,
  valueFromAssetToFiat,
  BelowInputInfoTable,
} from '../../../../common';
import { useWithdrawalForm } from './useWithdrawalForm';
import { getAppConfig } from '@bts-web/core-app-config';
import {
  ConvertionSettings,
  getConvertedSummary,
} from '../../../../trade/TradeParent/utils/getConvertedSummary';
import { AssetType } from '../../../../trade/utils/reusableTypes';

interface ChooseAmountScreenProps {
  cryptoTransferState: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

export const ChooseAmountScreen = ({
  cryptoTransferState,
  translations,
}: ChooseAmountScreenProps) => {
  const locale = getLocale(i18n.resolvedLanguage as Language);

  const { currency } = getAppConfig();

  const { selectedAssetData, selectedNetworkData } = cryptoTransferState;

  const {
    formState: {
      amountInputValue,
      amountInputValueWithMinFees,
      amountInputValueType,
      amountInputErrors,
      isLoading,
    },
    handlers: {
      onSubmit,
      changeAmountValueType,
      validateInputAndCheckForThresholdsErrors,
    },
  } = useWithdrawalForm({ cryptoTransferState });

  const { t } = useClientTranslation();

  return (
    <>
      <TradeAssetInfoDisplay
        assetData={{
          name: selectedAssetData?.name as string,
          logoUrl: selectedAssetData?.logoUrl as string,
        }}
        symbol={selectedAssetData?.symbol as string}
        price={selectedAssetData?.price}
        settings={{
          hideTopBorder: true,
        }}
      />

      <form
        onSubmit={onSubmit}
        className={css({
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          gap: 'extra_small_2',
        })}
      >
        <AmountInputWrapper
          inputContextData={{
            displayType: amountInputValueType,
            value: amountInputValue,
            currency,
            symbol: selectedAssetData?.symbol as string,
            placeholder: getAmountInputPlaceHolder({
              locale,
              currency,
              symbol: selectedAssetData?.symbol as string,
              displayType: amountInputValueType,
            }),
          }}
          switcherElement={
            <DisplaySwitchWrapper
              parentValue={amountInputValueType}
              items={[
                {
                  label: translations.fiat,
                  value: 'FIAT' as AmountFor,
                },
                {
                  label: translations.units,
                  value: 'ASSET' as AmountFor,
                },
              ]}
              onChange={(value) => changeAmountValueType(value)}
            />
          }
        >
          {({ inputRef, modifiedBlur }) => {
            return (
              <AmountInput
                ref={inputRef}
                placeholder={getAmountInputPlaceHolder({
                  locale,
                  currency,
                  symbol: selectedAssetData?.symbol as string,
                  displayType: amountInputValueType,
                })}
                value={amountInputValue}
                onChange={(e) =>
                  validateInputAndCheckForThresholdsErrors(
                    e.target.value,
                    amountInputValueType,
                  )
                }
                onBlur={modifiedBlur}
              />
            );
          }}
        </AmountInputWrapper>

        <div
          data-testid="choose-amount-screen-below-input"
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: 'small',
          })}
        >
          {amountInputErrors.minWithdrawalAmountNotReached && (
            <InputError
              message={`${t('crypto_withdrawal_minimum_amount_error_subtitle', {
                coin: selectedAssetData?.name,
                amount:
                  amountInputValueType === 'FIAT'
                    ? `${
                        valueFromAssetToFiat(
                          selectedNetworkData?.minWithdrawalThreshold
                            ?.value as string,
                          selectedAssetData?.price,
                        )?.value
                      } EUR`
                    : `${getPrecisionFloatValue(selectedNetworkData?.minWithdrawalThreshold)} ${selectedAssetData?.symbol}`,
              })}`}
            />
          )}

          {amountInputErrors.portfolioAmountExceeded && (
            <InputError
              message={`${t(
                'crypto_withdrawal_portfolio_exceeded_error_subtitle',
                {
                  amount:
                    amountInputValueType === 'FIAT'
                      ? `${getPrecisionFloatValue(
                          selectedAssetData?.portfolio.fiatBalance,
                        )} EUR`
                      : `${getPrecisionFloatValue(selectedAssetData?.portfolio.assetBalance)} ${selectedAssetData?.symbol}`,
                  coin: selectedAssetData?.name,
                },
              )}`}
            />
          )}

          <BelowInputInfoTable
            items={[
              {
                title: translations['units'],
                value: getConvertedSummary({
                  assetPrice: selectedAssetData?.price,
                  tradeType: 'buy',
                  assetType: AssetType.CRYPTO,
                  currency,
                  displayType: amountInputValueType,
                  locale,
                  symbol: selectedAssetData?.symbol as string,
                  translations: {
                    inclFees: translations.inclFees,
                  } as ConvertionSettings['translations'],
                  value: amountInputValueWithMinFees,
                }),
              },
            ]}
          />
        </div>

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 'small',
            marginTop: 'auto',
          })}
        >
          <AlertBox variant="info">
            {translations.inlineInfoCryptoTransferNewWalletTitle}
          </AlertBox>

          <ButtonBase
            type="submit"
            isLoading={isLoading}
            visual="primary"
            disabled={
              !amountInputValue ||
              isLoading ||
              amountInputErrors.minWithdrawalAmountNotReached ||
              amountInputErrors.portfolioAmountExceeded
            }
          >
            {translations.next}
          </ButtonBase>
        </div>
      </form>
    </>
  );
};
