import { TPaginatedAssetsForTransfersResponse } from '../components/TransfersLoader/types';

export enum TransfersSectionVariants {
  Deposit = 'DEPOSIT',
  Send = 'SEND',
}

export enum TransfersCommonStepsIds {
  ChooseAsset = 'CHOOSE_ASSET',
  ChooseNetwork = 'CHOOSE_NETWORK',
  AssetAndNetworkSummary = 'ASSET_NETWORK_SUMMARY',
}

export enum TransfersDepositStepsIds {
  DepositWallet = 'DEPOSIT_WALLET',
}

export enum TransfersSendStepsIds {
  ChooseRecipient = 'CHOOSE_RECIPIENT',
  AddNewWallet = 'ADD_NEW_WALLET',
  MemoIsMissing = 'MEMO_IS_MISSING',
  ChooseAmount = 'CHOOSE_AMOUNT',
  Confirmation = 'CONFIRMATION',
  TwoFactorAuth = '2FA',
}

export type TAllAssetsForTransfer = NonNullable<
  TPaginatedAssetsForTransfersResponse['assets']
>;

export type TAssetForTransferArray = Array<
  NonNullable<NonNullable<TAllAssetsForTransfer['edges']>[number]>['node']
>;

export type TActionbarTitleState =
  | 'selectAsset'
  | 'chooseRecipient'
  | 'chooseAmount'
  | 'summary'
  | null
  | undefined;

export type TDepositScreensState = {
  assetId: string;
  recipientWalletId: string;
  amount: string;
};

export type DestinationTagValues = 'memo' | 'destination_tag' | 'message';
