import { FC, ReactNode } from 'react';
import { css } from '@bts-web/utils-style-engine';

type DepositWalletInfoFieldProps = {
  label: string;
  value?: string | ReactNode;
};

export const DepositWalletInfoField: FC<DepositWalletInfoFieldProps> = ({
  value,
  label,
}) => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderBottomWidth: '1px',
        borderColor: 'neutrals.divider',
        gap: 'extra_small_2',
        py: 'medium',
      })}
    >
      <span
        aria-label="wallet address label"
        className={css({
          fontSize: 'label.medium_medium',
          lineHeight: 'label.medium_medium',
          letterSpacing: 'label.medium_medium',
          fontWeight: 'label.medium_medium',
          color: 'neutrals.text_secondary',
          textAlign: 'start',
        })}
      >
        {label}
      </span>
      {typeof value === 'string' ? (
        <div
          className={css({
            flex: 1,
            textAlign: 'start',
            fontSize: 'body.medium',
            fontWeight: 'body.medium',
            letterSpacing: 'body.medium',
            lineHeight: 'body.medium',
          })}
        >
          {value}
        </div>
      ) : (
        value
      )}
    </div>
  );
};
