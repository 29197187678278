import { Icon } from '@bts-web/design-system/component/icon';
import {
  walletAddressItemStyles,
  avatarStyles,
  detailsStyles,
  walletNameStyles,
  walletSubtitleStyles,
} from './styles';
import { css } from '@bts-web/utils-style-engine';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';

export const NewWalletAddressItem = ({
  newWalletAddress,
  translations,
  onClick,
}: {
  newWalletAddress: string;
  translations: TCryptoTransferTranslations;
  onClick: (type: 'address' | 'name') => void;
}) => {
  return (
    <div
      role="button"
      aria-label={`wallet address`}
      data-testid={`new-wallet-address-${newWalletAddress}`}
      className={walletAddressItemStyles}
      onClick={() =>
        onClick(newWalletAddress.includes(' ') ? 'name' : 'address')
      }
    >
      <div className={avatarStyles}>
        <Icon icon="add" size="16" />
      </div>

      <div className={detailsStyles}>
        <div className={walletNameStyles}>
          <span className={css({ wordBreak: 'break-word' })}>
            {newWalletAddress}
          </span>
        </div>

        {newWalletAddress.includes(' ') ? (
          <span className={walletSubtitleStyles} data-testid="new-beneficiary">
            {translations.addNewWalletBeneficiary}
          </span>
        ) : (
          <span className={walletSubtitleStyles} data-testid="new-address">
            {translations.addNewWalletAddress}
          </span>
        )}
      </div>
    </div>
  );
};
