import { SkeletonElement } from '../../../../../common';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';

const DepositWalletInfoFieldSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderBottomWidth: '1px',
      borderColor: 'neutrals.divider',
      gap: 'extra_small_2',
      py: 'medium',
    })}
  >
    <SkeletonElement width="100px" height="20px" borderRadius="16px" />
    <div
      className={css({
        display: 'flex',
        justifyContent: 'space-between',
      })}
    >
      <SkeletonElement width="250px" height="24px" borderRadius="16px" />
      <SkeletonElement width="24px" height="24px" borderRadius="16px" />
    </div>
  </div>
);

export const DepositWalletSkeleton: FC = () => {
  return (
    <div
      data-testid="deposit-wallet-skeleton"
      className={css({
        display: 'flex',
        flexDirection: 'column',
        flex: 'auto',
        gap: 'medium',
      })}
    >
      <div
        className={css({
          display: 'flex',
          gap: 'medium',
          pt: 'medium',
          px: 'medium',
        })}
      >
        <SkeletonElement width="40px" height="40px" borderRadius="50%" />
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 'extra_small_3',
          })}
        >
          <SkeletonElement width="150px" height="24px" borderRadius="16px" />
          <SkeletonElement width="30px" height="18px" borderRadius="16px" />
        </div>
      </div>

      {/* QR Code */}
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'small',
          px: 'medium',
        })}
      >
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
          })}
        >
          <SkeletonElement width="160px" height="160px" borderRadius="6px" />
        </div>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 'extra_small_2',
          })}
        >
          <SkeletonElement width="30px" height="14px" borderRadius="6px" />
          <SkeletonElement width="24px" height="24px" borderRadius="6px" />
          <SkeletonElement width="40px" height="14px" borderRadius="6px" />
        </div>
      </div>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'screen_background.primary',
          p: 'medium',
          flex: 1,
          pb: 'large',
          borderRadius: '16px 16px 0px 0px',
          flexShrink: 0,
        })}
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
          })}
        >
          <DepositWalletInfoFieldSkeleton />

          <DepositWalletInfoFieldSkeleton />
        </div>

        <div className={css({ py: 'small' })}>
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: 'medium',
            })}
          >
            <div
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
              })}
            >
              <SkeletonElement
                height="18px"
                width="160px"
                borderRadius="16px"
              />
              <SkeletonElement height="18px" width="60px" borderRadius="16px" />
            </div>

            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: 'extra_small_3',
              })}
            >
              <div
                className={css({
                  display: 'flex',
                  justifyContent: 'space-between',
                })}
              >
                <SkeletonElement
                  height="18px"
                  width="80px"
                  borderRadius="16px"
                />
                <SkeletonElement
                  height="18px"
                  width="60px"
                  borderRadius="16px"
                />
              </div>

              <SkeletonElement
                height="16px"
                width="180px"
                borderRadius="16px"
              />
            </div>
          </div>
        </div>

        <SkeletonElement width="372px" height="76px" borderRadius="8px" />
      </div>
    </div>
  );
};
