import { defaultAssetImg } from '../../../../../common';
import Image from 'next/image';
import { Locale } from '@bts-web/utils-lokalise';
import { Icon } from '@bts-web/design-system/component/icon';
import {
  arrowNextStyle,
  avatarStyles,
  detailsStyles,
  networkLogoStyles,
  walletAddressItemStyles,
  walletAddressStyles,
  walletNameStyles,
  walletNameTextStyles,
  walletSubtitleStyles,
} from './styles';
import { shortenAddress } from './utils';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';

export interface WalletListItemProps {
  id: string;
  walletName: string;
  address: string;
  selected: boolean;
  createdAt: string;
  assetLogoUrl: string | null;
  networkLogoUrl: string | null;
  onClick: () => void;
}

export const WalletListItem = ({
  walletName,
  address,
  onClick,
  selected,
  assetLogoUrl,
  networkLogoUrl,
  createdAt,
  currentLocale,
  createdOnTranslation,
  translations,
}: WalletListItemProps & {
  currentLocale: Locale;
  createdOnTranslation: (locale: Locale, createdAtDate: string) => string;
  translations: TCryptoTransferTranslations;
}) => {
  return (
    <div
      data-testid={`wallet-address-${address}`}
      role="button"
      aria-label={`wallet address ${address}`}
      className={walletAddressItemStyles}
      onClick={onClick}
      data-selected={selected}
    >
      <div className={avatarStyles}>
        {assetLogoUrl ? (
          <Image src={assetLogoUrl} alt="Asset logo" width={40} height={40} />
        ) : (
          <Image
            src={defaultAssetImg}
            alt={translations.altDefaultAssetLogo}
            width={40}
            height={40}
          />
        )}
        <div className={networkLogoStyles}>
          {networkLogoUrl && (
            <Image
              src={networkLogoUrl}
              alt="Network logo"
              width={18}
              height={18}
            />
          )}
        </div>
      </div>

      <div className={detailsStyles}>
        <div className={walletNameStyles}>
          <p className={walletNameTextStyles}>{walletName}</p>
        </div>

        <span className={walletAddressStyles}>{shortenAddress(address)}</span>

        <span className={walletSubtitleStyles}>
          {createdOnTranslation(currentLocale, createdAt)}
        </span>
      </div>

      <div className={arrowNextStyle}>
        <Icon icon="arrow-head-right" size="24" />
      </div>
    </div>
  );
};
