import { useClientTranslation } from '@bts-web/utils-lokalise';
import { css, stack } from '@bts-web/utils-style-engine';
import { ModalComponent } from '@bts-web/utils-context';
import { TTransfersNextStepStateWithActions } from '../../../../utils/useTransfersNextStepState.client';
import {
  DestinationTagValues,
  TransfersCommonStepsIds,
  TransfersDepositStepsIds,
} from '../../../../types';
import { RedirectToAssetSelection } from '../redirects/RedirectToAssetSelection';
import { RedirectToNetworkSelection } from '../redirects/RedirectToNetworkSelection';
import { AlertBox, ButtonBase, DialogBase } from '../../../../../common';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';
import { TransferWarningHeaderBox } from '../../../Reusable/TransferWarningHeaderBox/TransferWarningHeaderBox';
import { DepositWarningInfoField } from './DepositWarningInfoField';

interface DepositWarningModalProps {
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

const DepositWarningModal: ModalComponent<DepositWarningModalProps> = ({
  state,
  translations,
  onClose,
}) => {
  const { t } = useClientTranslation();

  const { selectedAssetData, selectedNetworkData } = state;

  const onDepositClick = () => {
    onClose();

    state.changeToPageAndBindActions({
      pageId: TransfersDepositStepsIds.DepositWallet,
      newBackAction: {
        pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
        type: 'page',
      },
    });
  };

  if (!selectedAssetData) {
    return <RedirectToAssetSelection state={state} />;
  }

  if (!selectedNetworkData) {
    return <RedirectToNetworkSelection state={state} />;
  }

  return (
    <DialogBase
      id="deposit-warning-modal"
      open
      size="medium"
      onMobileFullScreen
      visual="secondary"
      disableBackdropClose
      onOpenChange={onClose}
      title={translations.cryptoDepositNavigationBarTitle}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'large',
          height: '100%',
          textAlign: 'start',
        })}
      >
        <TransferWarningHeaderBox
          iconName="send"
          title={translations.cryptoDepositDrawerTitle}
        />
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            gap: 'large',
          })}
        >
          <div
            className={css({
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              gap: 'extra_small',
            })}
          >
            <p
              className={css({
                fontSize: 'body.medium',
                lineHeight: 'body.medium',
                letterSpacing: 'body.medium',
                fontWeight: 'body.medium',
                color: 'neutrals.text_primary',
              })}
            >
              {translations.cryptoDepositDrawerSubtitle}
            </p>
            <div
              className={stack({
                gap: 'extra_small',
                paddingBottom: 'small',
              })}
            >
              <DepositWarningInfoField
                label={
                  <>
                    <span>{translations.cryptoDepositUseNetwork}</span>
                    &nbsp;
                    <span>
                      {t('crypto_deposit_warning_network', {
                        networkname: selectedNetworkData?.name,
                      })}
                    </span>
                  </>
                }
              />
              <DepositWarningInfoField
                label={
                  <>
                    <span>{translations.cryptoDepositSendOnly}</span>
                    &nbsp;
                    <span>
                      {selectedAssetData?.name} ({selectedAssetData.symbol})
                    </span>
                  </>
                }
              />

              {selectedNetworkData?.destinationTag && (
                <DepositWarningInfoField
                  label={
                    <>
                      {t('crypto_deposit_warning_add_transaction', {
                        destinationTag:
                          translations[
                            selectedNetworkData?.destinationTag as DestinationTagValues
                          ].toLocaleLowerCase(),
                      })}
                    </>
                  }
                />
              )}
            </div>
            <AlertBox variant="warning">
              {translations.inline_warning_asset_network_lost}
            </AlertBox>
          </div>
        </div>

        <footer
          className={css({
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <div className={css({ maxWidth: '342px', width: '100%' })}>
            <ButtonBase onClick={onDepositClick} visual="primary">
              {translations.iUnderstand}
            </ButtonBase>
          </div>
        </footer>
      </div>
    </DialogBase>
  );
};

export { DepositWarningModal };
