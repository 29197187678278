import { useMemo, useState } from 'react';
import { CryptoTransfersVASPQuery$data } from '@bts-web/data-layer/server';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import {
  ButtonBase,
  ListCellsSelectionItem,
  ListCellsSelectionRoot,
  NavigationBar,
  SearchBar,
} from '../../../../common';
import { TWalletHostsTranslations } from '../../../utils/getCryptoTransferTranslations';
import { CryptoTransfersVASPNode } from '../../TransfersLoader/types';

export interface SelectWalletHostScreenProps {
  translations: TWalletHostsTranslations;
  walletHosts: CryptoTransfersVASPQuery$data['cryptoTransfersVASP'];
  selectedWalletHost: CryptoTransfersVASPNode;
  onSelectWalletHost: (selectedWalletHost: CryptoTransfersVASPNode) => void;
  onClose: () => void;
}

const matchHostByName = (name: string, value: string): boolean => {
  return value.length > 2
    ? name.toLowerCase().includes(value.toLowerCase())
    : true;
};

export enum OtherWalletId {
  OTHER = 'other_wallet',
  SELF_HOSTED = 'self_hosted_wallet',
}

const listTitleStyles = css({
  color: 'neutrals.text_secondary',
  fontSize: 'caption.small_light',
  fontWeight: 'caption.small_light',
  letterSpacing: 'caption.small_light',
  lineHeight: 'caption.small_light',
  paddingTop: 'large',
  paddingBottom: 'extra_small_2',
  paddingX: 'medium',
});

const WalletHostListItem = ({
  id,
  label,
  subLabel,
  activeItemId,
}: {
  id: string;
  label: string;
  subLabel?: string;
  activeItemId: string;
}) => (
  <ListCellsSelectionItem
    visual="checkmark"
    key={id}
    value={id}
    separator
    {...{
      id,
      activeItemId,
      label,
      subLabel,
    }}
  />
);

const SelectWalletHostScreen = ({
  selectedWalletHost,
  onSelectWalletHost,
  translations,
  walletHosts,
  onClose,
}: SelectWalletHostScreenProps) => {
  const [searchValue, setSearchValue] = useState('');

  const popularHosts = useMemo(() => {
    return walletHosts?.edges?.filter(
      (edge) =>
        edge?.node?.category === 'POPULAR' &&
        matchHostByName(edge.node.name, searchValue),
    );
  }, [walletHosts, searchValue]);

  const otherHosts = useMemo(() => {
    return walletHosts?.edges?.filter(
      (edge) =>
        edge?.node?.category === 'OTHER' &&
        matchHostByName(edge.node.name, searchValue),
    );
  }, [walletHosts, searchValue]);

  const onValueChangeHandler = (selectedHostId: string) => {
    const selectedWalletData = Object.values(OtherWalletId).includes(
      selectedHostId as OtherWalletId,
    )
      ? {
          category: 'OTHER',
          id: selectedHostId,
          name:
            selectedHostId === OtherWalletId.SELF_HOSTED
              ? translations.selfHostedWallet
              : translations.otherHost,
        }
      : walletHosts?.edges?.find((edge) => edge?.node?.id === selectedHostId)
          ?.node;

    onSelectWalletHost(selectedWalletData as CryptoTransfersVASPNode);

    onClose();
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  return (
    <>
      <NavigationBar
        isTitleCentered
        title={translations.walletHost}
        NavigationRightSlot={
          <ButtonBase onClick={onClose} visual={'ghost'} size={'small'}>
            <Icon size="24" icon="dismiss" />
          </ButtonBase>
        }
      />
      <div
        className={css({
          paddingX: 'small',
          paddingY: 'extra_small_2',
        })}
      >
        <SearchBar
          onChange={onSearch}
          placeholder={translations.searchHosts}
          value={searchValue}
          searchIcon={<Icon size="24" icon="search" />}
          dismissIcon={<Icon size="20" icon="dismiss" />}
        />
      </div>
      <ListCellsSelectionRoot
        onValueChange={onValueChangeHandler}
        className={css({
          overflow: 'auto',
          paddingBottom: '100px',
        })}
        value={selectedWalletHost?.id}
      >
        <WalletHostListItem
          id={OtherWalletId.SELF_HOSTED}
          activeItemId={selectedWalletHost?.id as string}
          label={translations.selfHostedWallet}
          subLabel={translations.selfHostedWalletDescription}
        />
        <div className={listTitleStyles}>{translations.mostPopularHosts}</div>
        {popularHosts?.map((host) => (
          <WalletHostListItem
            key={host?.node?.id}
            id={host?.node?.id as string}
            activeItemId={selectedWalletHost?.id as string}
            label={host?.node?.name as string}
          />
        ))}
        <div className={listTitleStyles}>{translations.moreWalletHosts}</div>
        {otherHosts?.map((host) => (
          <WalletHostListItem
            key={host?.node?.id}
            id={host?.node?.id as string}
            activeItemId={selectedWalletHost?.id as string}
            label={host?.node?.name as string}
          />
        ))}
        <WalletHostListItem
          id={OtherWalletId.OTHER}
          activeItemId={selectedWalletHost?.id as string}
          label={translations.otherHost}
        />
      </ListCellsSelectionRoot>
    </>
  );
};

export { SelectWalletHostScreen };
