import {
  CreateExternalCryptoAddressInput,
  CryptoTransfersVASPQuery$data,
} from '@bts-web/data-layer/server';
import { AddNewWalletForm } from '../../Reusable/AddNewWalletForm/AddNewWalletForm';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { TransfersSendStepsIds } from '../../../types';
import { useTransferWarningModal } from '../hooks/useTransferWarningModal';

interface AddNewWalletScreenProps {
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
  walletHosts: CryptoTransfersVASPQuery$data['cryptoTransfersVASP'];
}

const AddNewWalletScreen = ({
  state,
  translations,
  walletHosts,
}: AddNewWalletScreenProps) => {
  const {
    selectedAssetData,
    selectedNetworkData,
    setAddressInput,
    addressInput,
  } = state;

  const { showTransferWarningModal } = useTransferWarningModal(
    state,
    translations,
  );

  const onSubmitForm = (newAddress: CreateExternalCryptoAddressInput) => {
    const memoIsMissing =
      selectedNetworkData?.destinationTag && !newAddress.destinationTag;

    setAddressInput(newAddress);

    if (memoIsMissing) {
      state.changeToPageAndBindActions({
        pageId: TransfersSendStepsIds.MemoIsMissing,
        newBackAction: {
          pageId: TransfersSendStepsIds.AddNewWallet,
          type: 'page',
        },
      });

      return;
    }

    showTransferWarningModal(newAddress);
  };

  return (
    <AddNewWalletForm
      translations={translations}
      assetData={selectedAssetData}
      networkData={selectedNetworkData}
      walletHosts={walletHosts}
      onSubmit={onSubmitForm}
      defaultAddressInput={addressInput}
    />
  );
};

export { AddNewWalletScreen };
