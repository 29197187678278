import { CreateExternalCryptoAddressInput } from '@bts-web/data-layer/server';
import { useComposableModal } from '@bts-web/utils-context';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/core-features/common';
import { useAppNotification } from '@bts-web/core-features/notifications';
import { TransferWarningModal } from '../TransferWarningModal/TransferWarningModal';
import { createExternalCryptoAddress } from '../actions/createExternalCryptoAddress.action';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { mapWalletResponseItemToDisplayData } from '../ChooseRecipientScreen/components/utils';
import { TransfersSendStepsIds } from '../../../types';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';

export const useTransferWarningModal = (
  state: TTransfersNextStepStateWithActions,
  translations: TCryptoTransferTranslations,
) => {
  const { bindWalletData } = state;

  const { showModal, onClose } = useComposableModal();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const { setAppNotification } = useAppNotification();

  const showTransferWarningModal = (
    newAddressInput: CreateExternalCryptoAddressInput,
  ) => {
    showModal(TransferWarningModal, {
      translations,
      onSubmit: () => {
        createExternalCryptoAddress(newAddressInput)
          .then((response) => {
            if (response.errors) {
              processErrors(response.errors);

              return;
            }

            const newAddressData = mapWalletResponseItemToDisplayData(
              response.data?.createExternalCryptoAddress,
            );

            bindWalletData(newAddressData);

            setAppNotification({
              title: translations.addNewWalletSuccessTitle,
              subtitle: translations.addNewWalletSuccessSubtitle,
              visual: 'success',
              customDuration: 3000,
              position: 'bottomRight',
            });

            state.changeToPageAndBindActions({
              pageId: TransfersSendStepsIds.ChooseAmount,
              newBackAction: {
                pageId: TransfersSendStepsIds.AddNewWallet,
                type: 'page',
              },
            });
          })
          .catch((error) => {
            processErrors([error]);
          })
          .finally(() => {
            onClose();
          });
      },
    });
  };

  return {
    showTransferWarningModal,
  };
};
