'use client';

import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import {
  useComposableDrawer,
  useComposableModal,
} from '@bts-web/utils-context';
import { TabBar } from '../../../common/components/TabBar/TabBar';
import { useTransfersState } from '../../utils/useTransfersState';
import { TransfersSectionVariants } from '../../types';
import { DepositScreen } from '../DepositScreen/DepositScreen';
import { SendScreen } from '../SendScreen/SendScreen';
import { TCryptoTransferTranslations } from '../../utils/getCryptoTransferTranslations';
import { useTransfersNextStepState } from '../../utils/useTransfersNextStepState.client';
import { useTransfersAssetsListContext } from '../TransfersAssetListContext/TransfersAssetListContext';
import {
  AccessModalInfoType,
  TriggerSetupTwoFaModal,
  UserAccessModal,
  useClientUserInfo,
} from '../../../common';

export interface ITransfersParentProps {
  translations: TCryptoTransferTranslations;
  transferType?: TransfersSectionVariants;
}

const TransfersParent: FC<ITransfersParentProps> = ({
  translations,
  transferType,
}) => {
  const { isReadOnlyUser, twoFactorAuthentication } = useClientUserInfo();

  const { closeDrawer } = useComposableDrawer();

  const { showModal } = useComposableModal();

  const { currentSection, setCurrentSection } = useTransfersState(transferType);

  const { assetsListForDeposit, assetsListForSend } =
    useTransfersAssetsListContext();

  const { nextStepState: depositState } = useTransfersNextStepState({
    closeTransferModule: () => closeDrawer(),
    assetList: assetsListForDeposit,
    translations: translations,
  });

  const { nextStepState: sendState } = useTransfersNextStepState({
    closeTransferModule: () => closeDrawer(),
    assetList: assetsListForSend,
    translations: translations,
  });

  const bothStatesAreClean =
    depositState.isStateClean && sendState.isStateClean;

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
      })}
    >
      {!transferType && bothStatesAreClean && (
        <TabBar
          onValueChange={(newValue) => {
            if (newValue === TransfersSectionVariants.Send) {
              if (twoFactorAuthentication !== 'ENABLED') {
                showModal(TriggerSetupTwoFaModal, {
                  onModalClose: () => {
                    setCurrentSection(TransfersSectionVariants.Deposit);
                  },
                });

                return;
              }

              if (isReadOnlyUser) {
                showModal(UserAccessModal, {
                  type: AccessModalInfoType.ReadOnly,
                  onModalClose: () => {
                    setCurrentSection(TransfersSectionVariants.Deposit);
                  },
                });

                return;
              }
            }

            setCurrentSection(newValue as TransfersSectionVariants);
          }}
          value={currentSection}
          tabItems={[
            {
              label: translations.deposit,
              value: TransfersSectionVariants.Deposit,
              disabled: false,
            },
            {
              label: translations.send,
              value: TransfersSectionVariants.Send,
              disabled: assetsListForSend && assetsListForSend.length === 0,
            },
          ]}
        />
      )}

      {currentSection === TransfersSectionVariants.Deposit ? (
        <DepositScreen
          state={depositState}
          transfersTranslations={translations}
          isReadOnlyUser={isReadOnlyUser}
        />
      ) : (
        <SendScreen
          state={sendState}
          transfersTranslations={translations}
          isReadOnlyUser={isReadOnlyUser}
        />
      )}
    </div>
  );
};

export { TransfersParent };
