import { TFunction } from 'i18next';

export type TCryptoTransferTranslations = TActionBarTranslations &
  TAddNewWalletTranslations & {
    deposit: string;
    via: string;
    send: string;
    memoMissingButton: string;
    memoEmpty: string;
    searchAssets: string;
    copy: string;
    walletAddress: string;
    successToastAddressTitle: string;
    successToastDestinationTagTitle: string;
    successToastMemoTitle: string;
    generalErrorCopyToastTitle: string;
    fees: string;
    minimumDepositAmount: string;
    cryptoWithdrawalAddressWarning: string;
    cryptoWithdrawalCtaCancel: string;
    cryptoWithdrawalFraudWarning: string;
    cryptoWithdrawalLossWarning: string;
    cryptoWithdrawalNetworkWarning: string;
    cryptoDepositSendOnly: string;
    cryptoDepositUseNetwork: string;
    cryptoWithdrawalSubtitle: string;
    cryptoWithdrawalTitle: string;
    cryptoDepositNavigationBarTitle: string;
    cryptoWithdrawalRecipientErrorTitle: string;
    cryptoWithdrawalRecipientErrorSubtitle: string;
    cryptoWithdrawalMemoWarning: string;
    cryptoWithdrawalMemoMissingText: string;
    cryptoWithdrawalMemoErrorTitle: string;
    cryptoWithdrawalMemoErrorSubtitle: string;
    cryptoTransferWarningTitle: string;
    cryptoTransferWarningSubtitle: string;
    cryptoTransferDrawerListItemNetwork: string;
    cryptoTransferWarningListItemWalletAddress: string;
    cryptoTransferWarningListItemMemo: string;
    cryptoTransferWarningAddInfo: string;
    units: string;
    fiat: string;
    generalErrorHeadline: string;
    inlineInfoCryptoTransferNewWalletTitle: string;
    total: string;
    wallet: string;
    walletCreatedAt: string;
    transfer: string;
    address: string;
    externalWallet: string;
    transactionFees: string;
    continue: string;
    cryptoWithdrawalConfirmationOnNetwork: string;
    cryptoWithdrawalConfirmationToRecipient: string;
    cryptoWithdrawalConfirmationToYourself: string;
    cryptoWithdrawalConfirmationText: string;
    cryptoWithdrawal2FATitle: string;
    cryptoWithdrawal2FASubtitle: string;
    cryptoWithdrawal2FAInvalidCode: string;
    done: string;
    cryptoWithdrawalSuccessBody: string;
    cryptoWithdrawalSuccessSubtitle: string;
    savedWallets: string;
    goToPortfolio: string;
    inlineInfoFirstCryptoTransferTitle: string;
    cryptoDepositDrawerTitle: string;
    cryptoDepositDrawerSubtitle: string;
    iUnderstand: string;
    noAssetsInformationTitle: string;
    noAssetsInformation: string;
    noAssetsInformationButton: string;
    tryAgain: string;
    errorWalletList: string;
    invalidWalletSearchMessage: string;
    invalidFormatTitle: string;
    addNewWalletBeneficiary: string;
    addNewWalletAddress: string;
    walletSearchPlaceholder: string;
    altDefaultAssetLogo: string;
    addNewWalletSuccessTitle: string;
    addNewWalletSuccessSubtitle: string;
    inclFees: string;
  };

export type TActionBarTranslations = {
  selectAsset: string;
  chooseRecipient: string;
  chooseAmount: string;
  summary: string;
  selectNetwork: string;
  enterMemo: string;
  assetAndNetwork: string;
  toast_info_one_network_subtitle: string;
  toast_info_one_network_title: string;
  inline_warning_asset_network_lost: string;
  cryptocurrency: string;
  network: string;
  viewWalletAddressButton: string;
  next: string;
};

export type TAddNewWalletTranslations = {
  memoLabel: string;
  walletAddress: string;
  walletDetails: string;
  recipientsName: string;
  firstAndLastName: string;
  walletBelongsToMe: string;
  walletCreationDisclaimer: string;
  createNewWallet: string;
  hostName: string;
  otherHost: string;
  otherHostDescription: string;
  chooseWalletHost: string;
  send: string;
  errorInvalidFormatRecipientName: string;
  errorCheckWalletAddress: string;
  selfHostedInfoTitle: string;
  selfHostedInfoSubtitle: string;
  learnMore: string;
  hostNameSubtitle: string;
  memo: string;
  destination_tag: string;
  message: string;
  errorCheckMemo: string;
  cryptoWithdrawalMemoIsMissingTitle: string;
  cryptoWithdrawalMemoMissingText: string;
  memoMissingButton: string;
  iUnderstand: string;
  cryptoWithdrawalCtaCancel: string;
  cryptoWithdrawalReview: string;
} & TWalletHostsTranslations;

export type TWalletHostsTranslations = {
  walletHost: string;
  searchHosts: string;
  selfHostedWallet: string;
  selfHostedWalletDescription: string;
  mostPopularHosts: string;
  moreWalletHosts: string;
  otherHost: string;
  chooseWalletHost: string;
};

export const cryptoTransferTranslationsMap: TCryptoTransferTranslations = {
  via: 'via',
  chooseAmount: 'choose_amount',
  chooseRecipient: 'choose_recipient',
  deposit: 'deposit',
  memo: 'memo',
  memoMissingButton: 'memo_drawer_button',
  memoEmpty: 'memo_empty',
  memoLabel: 'memo_label',
  destination_tag: 'destination_tag',
  message: 'message',
  cryptoWithdrawalMemoIsMissingTitle: 'memo_missing_drawer_title',
  selectAsset: 'choose_asset',
  selectNetwork: 'choose_network',
  searchAssets: 'search_assets',
  send: 'send',
  summary: 'summary',
  assetAndNetwork: 'deposit_asset_and_network',
  enterMemo: 'enter_memo',
  copy: 'copy',
  walletAddress: 'wallet_address',
  walletCreatedAt: 'wallet_created_at',
  walletHost: 'wallet_host',
  createNewWallet: 'create_new_wallet',
  firstAndLastName: 'first_and_last_name',
  hostName: 'host_name',
  otherHost: 'other_host',
  otherHostDescription: 'other_host_description',
  chooseWalletHost: 'choose_wallet_host',
  recipientsName: 'recipients_name',
  walletBelongsToMe: 'wallet_belongs_to_me',
  walletCreationDisclaimer: 'wallet_creation_disclaimer',
  walletDetails: 'wallet_details',
  successToastDestinationTagTitle: 'success_toast_destination_tag_title',
  successToastMemoTitle: 'success_toast_memo_title',
  successToastAddressTitle: 'success_toast_address_title',
  generalErrorCopyToastTitle: 'general_error_copy_toast_title',
  toast_info_one_network_subtitle: 'toast_info_one_network_subtitle',
  toast_info_one_network_title: 'toast_info_one_network_title',
  inline_warning_asset_network_lost: 'inline_warning_asset_network_lost',
  cryptocurrency: 'deposit_choose_asset_crypto',
  network: 'network',
  viewWalletAddressButton: 'view_wallet_address_button',
  next: 'next',
  fees: 'fees',
  minimumDepositAmount: 'minimum_deposit_amount',
  cryptoWithdrawalReview: 'crypto_transfer_warning_memo_review',
  cryptoWithdrawalAddressWarning: 'crypto_withdrawal_address_warning',
  cryptoWithdrawalCtaCancel: 'crypto_withdrawal_cta_cancel',
  cryptoWithdrawalFraudWarning: 'crypto_withdrawal_fraud_warning',
  cryptoWithdrawalLossWarning: 'crypto_withdrawal_loss_warning',
  cryptoWithdrawalNetworkWarning: 'crypto_withdrawal_network_warning',
  cryptoWithdrawalSubtitle: 'crypto_withdrawal_subtitle',
  cryptoWithdrawalTitle: 'crypto_withdrawal_title',
  cryptoWithdrawalRecipientErrorTitle:
    'crypto_withdrawal_choose_recipient_error_title',
  cryptoWithdrawalRecipientErrorSubtitle:
    'crypto_withdrawal_choose_recipient_error_subtitle',
  cryptoWithdrawalMemoWarning: 'crypto_withdrawal_memo_warning',
  cryptoWithdrawalMemoMissingText: 'memo_missing_drawer_subtitle',
  cryptoWithdrawalMemoErrorTitle: 'crypto_withdrawal_memo_error_toast_title',
  cryptoWithdrawalMemoErrorSubtitle:
    'crypto_withdrawal_memo_error_toast_subtitle',
  cryptoTransferWarningAddInfo: 'crypto_transfer_warning_add_info',
  errorCheckMemo: 'crypto_withdrawal_memo_error_toast_title',
  cryptoTransferDrawerListItemNetwork:
    'crypto_transfer_warning_list_item_network',
  cryptoTransferWarningListItemMemo: 'crypto_transfer_warning_list_item_memo',
  cryptoTransferWarningSubtitle: 'crypto_transfer_drawer_subtitle',
  cryptoTransferWarningTitle: 'crypto_transfer_drawer_title',
  cryptoTransferWarningListItemWalletAddress:
    'crypto_transfer_warning_list_item_wallet_address',
  generalErrorHeadline: 'general_error_headline',
  fiat: 'eur_label',
  units: 'units',
  inlineInfoCryptoTransferNewWalletTitle:
    'inline_info_crypto_transfer_new_wallet_title',
  total: 'total',
  wallet: 'wallet',
  transfer: 'transfer_button',
  address: 'address',
  externalWallet: 'external_wallet',
  transactionFees: 'transaction_fees',
  continue: 'continue',
  cryptoWithdrawalConfirmationOnNetwork: 'crypto_withdrawal_success_on_network',
  cryptoWithdrawalConfirmationToRecipient:
    'crypto_withdrawal_success_to_recipient',
  cryptoWithdrawalConfirmationToYourself:
    'crypto_withdrawal_success_to_yourself',
  cryptoWithdrawalConfirmationText: 'crypto_withdrawal_confirmation_text',
  cryptoWithdrawal2FATitle: '2fa_reset_appcode_title',
  cryptoWithdrawal2FASubtitle: 'crypto_withdrawal_2fa_subtitle',
  cryptoWithdrawal2FAInvalidCode: '2fa_reset_invalid_code',
  done: 'done',
  cryptoWithdrawalSuccessBody: 'crypto_withdrawal_success_body',
  cryptoWithdrawalSuccessSubtitle: 'crypto_withdrawal_success_subtitle',
  savedWallets: 'saved_wallets',
  goToPortfolio: 'go_to_portfolio',
  inlineInfoFirstCryptoTransferTitle: 'inline_info_first_crypto_transfer_title',
  cryptoDepositDrawerTitle: 'crypto_deposit_warning_title',
  cryptoDepositNavigationBarTitle: 'crypto_deposit_navigation_bar_title',
  cryptoDepositDrawerSubtitle: 'crypto_deposit_warning_subtitle',
  iUnderstand: 'I_understand',
  noAssetsInformationTitle: 'no_assets_information_title',
  noAssetsInformation: 'no_assets_information',
  noAssetsInformationButton: 'no_assets_information_button',
  errorInvalidFormatRecipientName: 'error_invalid_format_recipient_name',
  errorCheckWalletAddress: 'error_check_wallet_address',
  moreWalletHosts: 'more_wallet_hosts',
  mostPopularHosts: 'most_popular_hosts',
  searchHosts: 'search_hosts',
  selfHostedWallet: 'self_hosted_wallet',
  selfHostedWalletDescription: 'self_hosted_wallet_description',
  selfHostedInfoTitle: 'self_hosted_info_title',
  selfHostedInfoSubtitle: 'self_hosted_info_subtitle',
  learnMore: 'learn_more',
  hostNameSubtitle: 'host_name_subtitle',
  cryptoDepositUseNetwork: 'crypto_deposit_warning_use_network',
  cryptoDepositSendOnly: 'crypto_deposit_warning_send_only',
  tryAgain: 'try_again',
  errorWalletList: 'error_wallet_list',
  invalidWalletSearchMessage: 'invalid_wallet_search_message',
  invalidFormatTitle: 'invalid_format_title',
  addNewWalletBeneficiary: 'add_new_wallet_beneficiary',
  addNewWalletAddress: 'add_new_wallet_address',
  walletSearchPlaceholder: 'wallet_search_placeholder',
  inclFees: 'incl_fees',
  altDefaultAssetLogo: 'alt_default_asset_logo', // "Default Asset logo"
  addNewWalletSuccessTitle: 'add_new_wallet_success_title',
  addNewWalletSuccessSubtitle: 'add_new_wallet_success_subtitle',
};

export const getCryptoTransferTranslations = (
  transUtil: TFunction,
): TCryptoTransferTranslations => {
  return Object.entries(cryptoTransferTranslationsMap).reduce(
    (acc, [key, value]) => {
      acc[key as keyof TCryptoTransferTranslations] = transUtil(value);

      return acc;
    },
    {} as TCryptoTransferTranslations,
  );
};
