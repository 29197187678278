import {
  type ChangeEvent,
  type FormEvent,
  useMemo,
  useState,
  useTransition,
} from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import {
  CreateExternalCryptoAddressInput,
  CryptoAddressHostType,
  CryptoTransfersVASPQuery$data,
} from '@bts-web/data-layer/server';
import { ComposableDrawerContextProvider } from '@bts-web/utils-context';
import {
  AssetImage,
  ButtonBase,
  CheckboxToggle,
  MainInput,
} from '../../../../common';
import { type TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { type TAddNewWalletTranslations } from '../../../utils/getCryptoTransferTranslations';
import { SelectWalletHostButton } from './SelectWalletHostButton';
import { CryptoTransfersVASPNode } from '../../TransfersLoader/types';
import { OtherWalletId } from '../SelectWalletHostScreen/SelectWalletHostScreen';
import { validateCryptoAddress } from '../../SendScreen/actions/validateCryptoAddress.action';
import { getMemoType } from '../../../utils/getMemoType';
import { DestinationTagValues } from '../../../types';

interface AddNewWalletFormProps extends React.PropsWithChildren {
  networkData: TTransfersNextStepStateWithActions['selectedNetworkData'];
  assetData: TTransfersNextStepStateWithActions['selectedAssetData'];
  walletHosts: CryptoTransfersVASPQuery$data['cryptoTransfersVASP'];
  translations: TAddNewWalletTranslations;
  onSubmit: (addressInput: CreateExternalCryptoAddressInput) => void;
  defaultAddressInput?: Partial<CreateExternalCryptoAddressInput> | null;
}

const inputSectionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small_2',
});

const inputStyles = css({
  width: '100%',
  padding: 'small',
  border: '1px solid',
  borderColor: 'neutrals.fill_tertiary',
  borderRadius: '4px',
  marginTop: 'extra_small_4',
  fontSize: 'label.large',
  fontWeight: 'label.large',
  lineHeight: 'label.large',
  letterSpacing: 'label.large',
  backgroundColor: 'white',
});

const inputTitleStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'label.medium_medium',
  fontWeight: 'label.medium_medium',
  lineHeight: 'label.medium_medium',
  letterSpacing: 'label.medium_medium',
});

const WALLET_NAME_REGEX = /[0-9a-zA-Z\-.']+\s[0-9a-zA-Z\-.']+/;

const WALLET_NAME_INPUT_MAX_LENGTH = 128;

const AddNewWalletForm = ({
  translations,
  assetData,
  networkData,
  walletHosts,
  onSubmit,
  children,
  defaultAddressInput,
}: AddNewWalletFormProps) => {
  const { t } = useClientTranslation();

  const selectedWalletHost = useMemo(() => {
    if (!defaultAddressInput?.hostName) return null;

    if (defaultAddressInput?.hostType === 'UNKNOWN_VASP') {
      return {
        id: OtherWalletId.OTHER,
        name: translations.otherHost,
        category: 'OTHER',
      } as CryptoTransfersVASPNode;
    }

    if (defaultAddressInput?.hostType === 'SELF_HOSTED') {
      return {
        id: OtherWalletId.SELF_HOSTED,
        name: translations.selfHostedWallet,
        category: 'OTHER',
      } as CryptoTransfersVASPNode;
    }

    return walletHosts?.edges?.find(
      (host) =>
        host?.node?.name === defaultAddressInput.hostName ||
        host?.node?.id === defaultAddressInput.hostName,
    )?.node;
  }, [defaultAddressInput, walletHosts]);

  const [recipientNameInputValue, setRecipientNameInputValue] = useState(
    defaultAddressInput?.ownerLegalName || '',
  );

  const [recipientNameError, setRecipientNameError] = useState('');

  const [walletAddressInputValue, setWalletAddressInputValue] = useState(
    defaultAddressInput?.address || '',
  );

  const [memoInputValue, setMemoInputValue] = useState('');

  const [memoInputError, setMemoInputError] = useState('');

  const [walletAddressInputError, setWalletAddressInputError] = useState('');

  const [isMyWallet, setIsMyWallet] = useState(
    defaultAddressInput?.userOwned || false,
  );

  const [walletHost, setWalletHost] = useState<CryptoTransfersVASPNode | null>(
    selectedWalletHost || null,
  );

  const [otherHostName, setOtherHostName] = useState('');

  const [isAgreed, setIsAgreed] = useState(false);

  const [isSubmitLoading, startTransition] = useTransition();

  const onChangeRecipientInput = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(false);

    setRecipientNameInputValue(e.target.value);
  };

  const onChangeWalletAddressInput = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(false);

    setWalletAddressInputValue(e.target.value);
  };

  const onChangeMemoInput = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(false);

    setMemoInputValue(e.target.value);
  };

  const onChangeOtherHostNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAgreed(false);

    setOtherHostName(e.target.value);
  };

  const onInvalidRecipientNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const validity = e.target.validity;

    if (validity.patternMismatch) {
      setRecipientNameError(translations.errorInvalidFormatRecipientName);
    }
  };

  const onSubmitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formElement = e.target as HTMLFormElement;

    setRecipientNameError('');

    setWalletAddressInputError('');

    setMemoInputError('');

    if (!isMyWallet && !formElement.recipientNameInput.checkValidity()) {
      return;
    }

    const { errors: addressValidationErrors, data } =
      await validateCryptoAddress({
        coinNetworkId: networkData?.coinNetworkId as string,
        toAddress: walletAddressInputValue as string,
        ...(networkData?.destinationTag && memoInputValue
          ? {
              destinationTag: memoInputValue as string,
              destinationTagType: getMemoType(
                memoInputValue,
                assetData?.symbol as string,
              ),
            }
          : {}),
      });

    const validationResponseData = data?.validateCryptoAddress;

    if (
      addressValidationErrors ||
      !validationResponseData?.isAddressValid ||
      !validationResponseData?.isValid
    ) {
      if (validationResponseData?.isDestinationTagValid === false) {
        setMemoInputError(translations.errorCheckMemo);
      } else {
        setWalletAddressInputError(translations.errorCheckWalletAddress);
      }

      return;
    }

    let hostType: CryptoAddressHostType = 'KNOWN_VASP';

    if (walletHost?.id === OtherWalletId.SELF_HOSTED) {
      hostType = 'SELF_HOSTED';
    }

    if (walletHost?.id === OtherWalletId.OTHER) {
      hostType = 'UNKNOWN_VASP';
    }

    const newWalletInput: CreateExternalCryptoAddressInput = {
      address: walletAddressInputValue.trim(),
      hostName:
        walletHost?.id === OtherWalletId.OTHER
          ? otherHostName
          : (walletHost?.name as string),
      hostType,
      networkId: networkData?.coinNetworkId as string,
      ownerLegalName: recipientNameInputValue,
      userOwned: isMyWallet,
      ...(networkData?.destinationTag && memoInputValue
        ? {
            destinationTag: memoInputValue as string,
          }
        : {}),
    };

    onSubmit(newWalletInput);
  };

  const isNextButtonDisabled =
    !isAgreed ||
    (!isMyWallet && recipientNameInputValue === '') ||
    walletAddressInputValue === '' ||
    (walletHost?.id === OtherWalletId.OTHER
      ? otherHostName === ''
      : walletHost === null);

  return (
    <form
      data-testid="add-new-wallet-form"
      className={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        pt: 'small',
      })}
      onSubmit={(e) => {
        startTransition(() => {
          onSubmitForm(e);
        });
      }}
    >
      <h1
        className={css({
          fontSize: 'headline.large_semi_bold',
          fontWeight: 'headline.large_semi_bold',
          lineHeight: 'headline.large_semi_bold',
          letterSpacing: 'headline.large_semi_bold',
          paddingY: 'extra_small',
        })}
      >
        {translations.walletDetails}
      </h1>
      <div
        className={css({
          display: 'flex',
          gap: 'small',
          alignItems: 'center',
          paddingY: 'small',
          borderBottomWidth: '1px',
          borderColor: 'neutrals.divider',
        })}
      >
        <AssetImage
          src={assetData?.logoUrl}
          alt={assetData?.name as string}
          width={40}
          height={40}
        />
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: 'extra_small_2',
          })}
        >
          <span
            className={css({
              color: 'neutrals.text_primary',
              fontSize: 'title.large',
              fontWeight: 'title.large',
              lineHeight: 'title.large',
              letterSpacing: 'title.large',
            })}
          >
            {translations.send} {assetData?.name}
          </span>
          <span
            className={css({
              color: 'neutrals.text_secondary',
              fontSize: 'body.medium',
              fontWeight: 'body.medium',
              lineHeight: 'body.medium',
              letterSpacing: 'body.medium',
            })}
          >
            {t('on_network', {
              assetname: networkData?.name,
            })}
          </span>
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'medium',
          paddingY: 'small',
        })}
      >
        <div className={inputSectionStyles}>
          {!isMyWallet && (
            <>
              <span className={inputTitleStyles}>
                {translations.recipientsName}
              </span>
              <MainInput
                className={inputStyles}
                value={recipientNameInputValue}
                onChange={onChangeRecipientInput}
                onInvalid={onInvalidRecipientNameInput}
                placeholder={translations.firstAndLastName}
                pattern={WALLET_NAME_REGEX.source}
                maxLength={WALLET_NAME_INPUT_MAX_LENGTH}
                id="recipientNameInput"
              />
              {recipientNameError && (
                <div
                  className={css({
                    color: 'negative.text_primary',
                    display: 'flex',
                    gap: 'extra_small_3',
                  })}
                >
                  <Icon icon="dismiss-circle" theme="regular" size="12" />
                  <span
                    className={css({
                      fontSize: 'caption.small_light',
                      fontWeight: 'caption.small_light',
                      lineHeight: 'caption.small_light',
                      letterSpacing: 'caption.small_light',
                    })}
                  >
                    {recipientNameError}
                  </span>
                </div>
              )}
            </>
          )}

          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            })}
          >
            <span
              className={css({
                fontSize: 'body.medium',
                fontWeight: 'body.medium',
                lineHeight: 'body.medium',
                letterSpacing: 'body.medium',
              })}
            >
              {translations.walletBelongsToMe}
            </span>
            <CheckboxToggle
              variant="primary"
              size="small"
              checked={isMyWallet}
              onChange={() => {
                setIsMyWallet(!isMyWallet);

                setRecipientNameInputValue('');

                setIsAgreed(false);
              }}
            />
          </div>
        </div>
        <div className={inputSectionStyles}>
          <span className={inputTitleStyles}>{translations.walletAddress}</span>
          <MainInput
            className={inputStyles}
            value={walletAddressInputValue}
            onChange={onChangeWalletAddressInput}
            placeholder={translations.walletAddress}
          />
          {walletAddressInputError && (
            <div
              className={css({
                color: 'negative.text_primary',
                display: 'flex',
                gap: 'extra_small_3',
              })}
            >
              <Icon icon="dismiss-circle" theme="regular" size="12" />
              <span
                className={css({
                  fontSize: 'caption.small_light',
                  fontWeight: 'caption.small_light',
                  lineHeight: 'caption.small_light',
                  letterSpacing: 'caption.small_light',
                })}
              >
                {walletAddressInputError}
              </span>
            </div>
          )}
        </div>
        {networkData?.destinationTag && (
          <div className={inputSectionStyles}>
            <span className={inputTitleStyles}>{translations.memoLabel}</span>
            <MainInput
              className={inputStyles}
              value={memoInputValue}
              onChange={onChangeMemoInput}
              placeholder={
                translations[networkData.destinationTag as DestinationTagValues]
              }
            />
            {memoInputError && (
              <div
                className={css({
                  color: 'negative.text_primary',
                  display: 'flex',
                  gap: 'extra_small_3',
                })}
              >
                <Icon icon="dismiss-circle" theme="regular" size="12" />
                <span
                  className={css({
                    fontSize: 'caption.small_light',
                    fontWeight: 'caption.small_light',
                    lineHeight: 'caption.small_light',
                    letterSpacing: 'caption.small_light',
                  })}
                >
                  {memoInputError}
                </span>
              </div>
            )}
          </div>
        )}
        <div className={inputSectionStyles}>
          <span className={inputTitleStyles}>{translations.walletHost}</span>
          <ComposableDrawerContextProvider>
            <SelectWalletHostButton
              className={inputStyles}
              selectedWalletHost={walletHost}
              translations={translations}
              walletHosts={walletHosts}
              onSelectWalletHost={(selectedHost) => {
                setWalletHost(selectedHost);

                setIsAgreed(false);
              }}
            />
          </ComposableDrawerContextProvider>
        </div>
        {walletHost?.id === OtherWalletId.SELF_HOSTED && (
          <div
            className={css({
              border: '2px solid',
              borderColor: 'information.stroke_primary',
              borderRadius: '8px',
              padding: 'small',
              display: 'flex',
              flexDirection: 'column',
              gap: 'extra_small_2',
            })}
          >
            <span
              className={css({
                color: 'information.text_primary',
                fontSize: 'label.medium_medium',
                fontWeight: 'label.medium_medium',
                letterSpacing: 'label.medium_medium',
                lineHeight: 'label.medium_medium',
              })}
            >
              {translations.selfHostedInfoTitle}
            </span>
            <span
              className={css({
                color: 'neutrals.text_primary',
                fontSize: 'body.medium',
                fontWeight: 'body.medium',
                letterSpacing: 'body.medium',
                lineHeight: 'body.medium',
              })}
            >
              {translations.selfHostedInfoSubtitle}
            </span>
            {/* enable when link is provided */}
            {/* <HyperLinkButtonBase
              noPadding
              size="small"
              visual="ghost"
              className={css({
                paddingTop: 'extra_small_2',
                textDecoration: 'underline',
                width: 'fit-content',
              })}
              to="/"
            >
              {translations.learnMore}
            </HyperLinkButtonBase> */}
          </div>
        )}
        {walletHost?.id === OtherWalletId.OTHER && (
          <div className={inputSectionStyles}>
            <span className={inputTitleStyles}>{translations.hostName}</span>
            <MainInput
              className={inputStyles}
              value={otherHostName}
              onChange={onChangeOtherHostNameInput}
              placeholder={translations.hostName}
            />
            <span
              className={css({
                color: 'neutrals.text_secondary',
                fontSize: 'caption.small_light',
                fontWeight: 'caption.small_light',
                lineHeight: 'caption.small_light',
                letterSpacing: 'caption.small_light',
              })}
            >
              {translations.hostNameSubtitle}
            </span>
          </div>
        )}
        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          })}
        >
          <span
            className={css({
              fontSize: 'body.medium',
              fontWeight: 'body.medium',
              lineHeight: 'body.medium',
              letterSpacing: 'body.medium',
            })}
          >
            {translations.walletCreationDisclaimer}
          </span>
          <CheckboxToggle
            variant="primary"
            size="small"
            checked={isAgreed}
            onChange={() => {
              setIsAgreed(!isAgreed);
            }}
          />
        </div>
      </div>
      {/* for displaying extra info (e.g. for Deposit: https://www.figma.com/design/6c4F0lyd9WMeyKH5JSfkXx/Web-app-BTS---Mobile---Raiffeisen?node-id=14264-110590&t=sYqOg8JPuZ16cGkT-0 ) */}
      {children}
      <div
        className={css({
          marginTop: 'auto',
          paddingY: 'extra_small',
        })}
      >
        <ButtonBase
          disabled={isNextButtonDisabled}
          isLoading={isSubmitLoading}
          visual="primary"
          size="large"
          type="submit"
          fullWidth
        >
          {translations.createNewWallet}
        </ButtonBase>
      </div>
    </form>
  );
};

export { AddNewWalletForm };
