import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { Drawer } from '../../../../layouts/Drawer/Drawer';
import {
  SelectWalletHostScreen,
  type SelectWalletHostScreenProps,
} from '../SelectWalletHostScreen/SelectWalletHostScreen';
import { useComposableDrawer } from '@bts-web/utils-context';

interface SelectWalletHostProps
  extends Omit<SelectWalletHostScreenProps, 'onClose'> {
  className: string;
}

const SelectWalletHostButton = ({
  selectedWalletHost,
  onSelectWalletHost,
  className,
  translations,
  walletHosts,
}: SelectWalletHostProps) => {
  const { openDrawer, closeDrawer } = useComposableDrawer();

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
      })}
      onClick={() => {
        openDrawer(() => (
          <Drawer
            contentNode={
              <SelectWalletHostScreen
                walletHosts={walletHosts}
                translations={translations}
                selectedWalletHost={selectedWalletHost}
                onSelectWalletHost={onSelectWalletHost}
                onClose={closeDrawer}
              />
            }
          />
        ));
      }}
    >
      <input
        readOnly
        value={selectedWalletHost?.name || ''}
        placeholder={translations.chooseWalletHost}
        className={`${className} ${css({
          cursor: 'pointer',
        })}`}
      />
      <Icon
        className={css({
          right: 'small',
          position: 'absolute',
        })}
        icon="chevron-right"
        size="20"
      />
    </div>
  );
};

export { SelectWalletHostButton };
