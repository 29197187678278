import { FC } from 'react';
import {
  convertToLocaleDateString,
  getPrecisionFloatValue,
} from '@bts-web/utils-formatting';
import {
  getLocale,
  i18n,
  Language,
  useClientTranslation,
} from '@bts-web/utils-lokalise';
import { css } from '@bts-web/utils-style-engine';
import { TTransfersNextStepStateWithActions } from '../../../../utils/useTransfersNextStepState.client';
import { CopyToClipboardButton } from '../../../../../common';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';
import { useCryptoAddress } from '../../hooks/useCryptoAddress';
import { DestinationTagValues } from '../../../../types';
import { DepositWalletInfoField } from './DepositWalletInfoField/DepositWalletInfoField';
import { DepositInfoField } from './DepositInfoField';

const labelStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  flex: 1,
  textAlign: 'start',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

interface DepositAddressInfoProps {
  selectedAssetData: NonNullable<
    TTransfersNextStepStateWithActions['selectedAssetData']
  >;
  selectedNetworkData: NonNullable<
    TTransfersNextStepStateWithActions['selectedNetworkData']
  >;
  translations: TCryptoTransferTranslations;
}

const DepositAddressInfo: FC<DepositAddressInfoProps> = ({
  translations,
  selectedAssetData,
  selectedNetworkData,
}) => {
  const { t } = useClientTranslation();

  const locale = getLocale(i18n.resolvedLanguage as Language);

  const { cryptoAddress } = useCryptoAddress({
    translations: {
      title: t('general_error_message_title'),
      subtitle: t('info_toast_asset_unavailable_subtitle'),
    },
    coinNetworkId: selectedNetworkData?.coinNetworkId,
  });

  if (!cryptoAddress) {
    return null;
  }

  return (
    <>
      <div
        className={css({
          gap: 'extra_small_2',
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        <DepositWalletInfoField
          label={translations.walletAddress}
          value={
            <CopyToClipboardButton
              value={cryptoAddress.address}
              ariaLabel={`copy ${translations.walletAddress}`}
              visual="ghost"
              noBorder
              fullWidth
              successMessage={translations.successToastAddressTitle}
              failedMessage={translations.generalErrorCopyToastTitle}
            >
              <span className={labelStyles}>{cryptoAddress.address}</span>
            </CopyToClipboardButton>
          }
        />

        {cryptoAddress?.destinationTag && (
          <DepositWalletInfoField
            label={
              translations[
                selectedNetworkData?.destinationTag as DestinationTagValues
              ]
            }
            value={
              <CopyToClipboardButton
                value={cryptoAddress.destinationTag}
                ariaLabel={`copy ${translations.walletAddress}`}
                visual="ghost"
                noBorder
                fullWidth
                successMessage={
                  selectedNetworkData?.destinationTag === 'memo'
                    ? translations.successToastMemoTitle
                    : translations.successToastDestinationTagTitle
                }
                failedMessage={translations.generalErrorHeadline}
              >
                <span className={labelStyles}>
                  {cryptoAddress.destinationTag}
                </span>
              </CopyToClipboardButton>
            }
          />
        )}

        {cryptoAddress?.hostName && (
          <DepositWalletInfoField
            label={translations.walletHost}
            value={
              <CopyToClipboardButton
                value={cryptoAddress.hostName}
                ariaLabel={`copy ${translations.walletHost}`}
                visual="ghost"
                noBorder
                fullWidth
                successMessage={translations.successToastMemoTitle}
                failedMessage={translations.generalErrorHeadline}
              >
                <span className={labelStyles}>{cryptoAddress.hostName}</span>
              </CopyToClipboardButton>
            }
          />
        )}

        {cryptoAddress.createdAt && (
          <DepositWalletInfoField
            value={convertToLocaleDateString(cryptoAddress?.createdAt, locale)}
            label={translations.walletCreatedAt}
          />
        )}
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          py: 'small',
          gap: 'medium',
        })}
      >
        {selectedNetworkData.minDepositThreshold ? (
          <DepositInfoField
            value={`${getPrecisionFloatValue(selectedNetworkData.minDepositThreshold)} ${selectedAssetData.symbol}`}
            label={translations.minimumDepositAmount}
          />
        ) : null}

        {selectedNetworkData.smallDepositThreshold &&
        selectedNetworkData.smallDepositFee ? (
          <DepositInfoField
            value={`${getPrecisionFloatValue(selectedNetworkData.smallDepositFee)} ${selectedAssetData.symbol}`}
            label={translations.fees}
            caption={t('on_deposits_below', {
              assetamount: `${getPrecisionFloatValue(
                selectedNetworkData.smallDepositThreshold,
              )} ${selectedAssetData.symbol}`,
            })}
          />
        ) : null}
      </div>
    </>
  );
};

export { DepositAddressInfo };
