import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { Icon } from '@bts-web/design-system/component/icon';

const iconStyles = css({
  color: 'neutrals.fill_primary',
});

const warningItemStyles = css({
  alignItems: 'center',
  display: 'flex',
  gap: 'extra_small_2',
  pt: 'extra_small',
  '&:last-of-type': {
    pb: 'extra_small',
  },
});

interface TransferWarningInformationProps {
  translations: TCryptoTransferTranslations;
}

const TransferWarningInformation: FC<TransferWarningInformationProps> = ({
  translations,
}) => {
  const warningItems = [
    {
      icon: 'wallet',
      text: translations.cryptoTransferWarningListItemWalletAddress,
    },
    {
      icon: 'virtual-network',
      text: translations.cryptoWithdrawalNetworkWarning,
    },
    {
      icon: 'tag',
      text: translations.cryptoTransferWarningListItemMemo,
    },
  ];

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small_2',
        flex: 1,
        fontSize: 'body.medium_medium',
        fontWeight: 400,
        lineHeight: 'body.medium_medium',
        letterSpacing: 'body.medium_medium',
      })}
      data-testid="transfer-warning-information"
    >
      <p>{translations.cryptoTransferWarningSubtitle}</p>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        {warningItems.map((item) => (
          <div className={warningItemStyles} key={item.icon}>
            <Icon
              icon="checkmark-circle"
              size="16"
              theme="regular"
              data-element={`icon-${item.icon}`}
              className={iconStyles}
            />
            {item.text}
          </div>
        ))}
      </div>

      <p>{translations.cryptoTransferWarningAddInfo}</p>
    </div>
  );
};

export { TransferWarningInformation };
