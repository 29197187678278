import { FC } from 'react';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { css } from '@bts-web/utils-style-engine';
import { TTransfersNextStepStateWithActions } from '../../../../utils/useTransfersNextStepState.client';
import { RedirectToAssetSelection } from '../redirects/RedirectToAssetSelection';
import { RedirectToNetworkSelection } from '../redirects/RedirectToNetworkSelection';
import { AlertBox, AssetImage, QRCode } from '../../../../../common';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';
import { useCryptoAddress } from '../../hooks/useCryptoAddress';
import { DepositWalletSkeleton } from './DepositWalletSkeleton';
import { DepositAddressInfo } from './DepositAddressInfo';

interface DepositWalletPageProps {
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

const DepositWalletPage: FC<DepositWalletPageProps> = ({
  state,
  translations,
}) => {
  const { t } = useClientTranslation();

  const { selectedAssetData, selectedNetworkData } = state;

  const { isDataLoading, cryptoAddress } = useCryptoAddress({
    translations: {
      title: t('general_error_message_title'),
      subtitle: t('info_toast_asset_unavailable_subtitle'),
    },
    coinNetworkId: selectedNetworkData?.coinNetworkId,
  });

  if (!selectedAssetData) {
    return <RedirectToAssetSelection state={state} />;
  }

  if (!selectedNetworkData) {
    return <RedirectToNetworkSelection state={state} />;
  }

  if (isDataLoading) {
    return <DepositWalletSkeleton />;
  }

  return (
    <div
      className={css({
        display: 'flex',
        flex: 'auto',
        flexDirection: 'column',
        gap: 'medium',
      })}
      data-testid="deposit-wallet-address-info"
    >
      <div
        className={css({
          display: 'flex',
          gap: 'medium',
          pt: 'medium',
          px: 'medium',
        })}
      >
        <AssetImage
          width={40}
          height={40}
          alt={selectedAssetData.name}
          src={selectedAssetData.logoUrl}
          aria-label="asset image"
        />

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          })}
        >
          <span
            className={css({
              color: 'neutrals.text_primary',
              lineHeight: 'headline.small_medium',
              fontWeight: 'headline.small_medium',
              letterSpacing: 'headline.small_medium',
              fontSize: 'headline.small_medium',
              lineClamp: 2,
              textAlign: 'start',
            })}
          >
            {selectedAssetData.name}
          </span>
          <span
            className={css({
              fontSize: 'body.medium',
              fontWeight: 'body.medium',
              lineHeight: 'body.medium',
              letterSpacing: 'body.medium',
              color: 'neutrals.text_secondary',
            })}
          >
            {selectedAssetData.symbol}
          </span>
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'small',
          px: 'medium',
        })}
      >
        {cryptoAddress && (
          <QRCode
            size={140}
            value={cryptoAddress.address}
            logoImageUrl={selectedAssetData.logoUrl ?? undefined}
          />
        )}

        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 'extra_small_2',
            color: 'neutrals.fill_primary',
            fontSize: 'caption.small',
            fontWeight: 'caption.small',
            lineHeight: 'caption.small',
            letterSpacing: 'caption.small',
            textAlign: 'start',
          })}
        >
          {translations.via}
          {selectedNetworkData.logoUrl && (
            <AssetImage
              width={24}
              height={24}
              alt={selectedNetworkData.name || ''}
              src={selectedNetworkData.logoUrl}
              aria-label="asset image"
            />
          )}
          {selectedNetworkData?.name} {}
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          backgroundColor: 'screen_background.primary',
          p: 'medium',
          pb: 'large',
          borderRadius: '16px 16px 0px 0px',
          flexShrink: 0,
        })}
      >
        <DepositAddressInfo
          translations={translations}
          selectedAssetData={selectedAssetData}
          selectedNetworkData={selectedNetworkData}
        />

        <AlertBox variant="info">
          {translations.inlineInfoFirstCryptoTransferTitle}
        </AlertBox>
      </div>
    </div>
  );
};

export { DepositWalletPage };
