import { css } from '@bts-web/utils-style-engine';

export const walletAddressItemStyles = css({
  display: 'flex',
  alignItems: 'center',
  py: 'small',
  gap: 'small',
  borderBottom: '1px solid',
  borderColor: 'neutrals.divider',
  cursor: 'pointer',
  px: 'medium',
  marginInlineStart: 'calc(var(--spacing-medium) * -1)',
  marginInlineEnd: 'calc(var(--spacing-medium) * -1)',
  '&:hover, &[data-selected="true"]': {
    backgroundColor: 'neutrals.fill_focused',
  },
});

export const avatarStyles = css({
  width: '40px',
  height: '40px',
  minWidth: 'fit-content',
  borderRadius: '50%',
  backgroundColor: 'neutrals.fill_quaternary',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'caption.small',
  fontWeight: 'caption.small',
  lineHeight: 'caption.small',
  letterSpacing: 'caption.small',
  position: 'relative',
});

export const networkLogoStyles = css({
  width: '18px',
  height: '18px',
  borderRadius: '50%',
  backgroundColor: 'neutrals.fill_quaternary',
  color: 'neutrals.text_secondary',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  left: '23px',
  marginTop: '22px',
});

export const detailsStyles = css({
  flexGrow: 1,
  display: 'flex',
  flexDir: 'column',
  gap: 'extra_small_3',
  minWidth: 0,
});

export const walletNameStyles = css({
  fontSize: 'label.large',
  fontWeight: 'label.medium_medium',
  lineHeight: 'label.medium_medium',
  letterSpacing: 'label.medium_medium',
});

export const walletNameTextStyles = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

export const walletAddressStyles = css({
  fontSize: 'caption.medium_medium',
  fontWeight: 'caption.medium_medium',
  lineHeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
});

export const walletSubtitleStyles = css({
  color: 'neutrals.text_secondary',
  fontSize: 'caption.medium_medium',
  fontWeight: 'caption.medium_medium',
  lineHeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
});

export const arrowNextStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: 'neutrals.fill_tertiary',
});

export const centeredContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const errorBackgroundStyles = css({
  width: '58px',
  height: '58px',
  borderRadius: '24px',
  backgroundColor: 'neutrals.fill_quaternary',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 'caption.small',
  fontWeight: 'caption.small',
  lineHeight: 'caption.small',
  letterSpacing: 'caption.small',
  position: 'relative',
});

export const invalidTitleStyle = css({
  fontSize: 'caption.large',
  fontWeight: 'headline.large',
  lineHeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
  paddingTop: 'medium',
  textAlign: 'center',
});

export const invalidSubtitleStyle = css({
  color: 'neutrals.text_secondary',
  fontSize: 'caption.medium_medium',
  fontWeight: 'caption.medium_medium',
  lineHeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
  paddingTop: 'extra_small_2',
  paddingBottom: 'large',
  textAlign: 'center',
});

export const plusSignStyle = css({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: 'neutrals.fill_quaternary',
  color: 'neutrals.text_secondary',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});
