import { gqlNetworkFetch } from '@bts-web/utils-relay';
import {
  ExternalCryptoAddressesQuery,
  ExternalCryptoAddressesQueryNode,
  ListExternalCryptoAddressesInput,
} from '@bts-web/data-layer/server';

export const getExternalCryptoAddresses = async (
  input: ListExternalCryptoAddressesInput,
) => {
  const response = await gqlNetworkFetch<ExternalCryptoAddressesQuery>(
    ExternalCryptoAddressesQueryNode.params,
    {
      input,
    },
  );

  return response;
};
