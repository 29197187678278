import { Icon } from '@bts-web/design-system/component/icon';
import {
  centeredContainerStyle,
  errorBackgroundStyles,
  invalidTitleStyle,
  invalidSubtitleStyle,
} from './styles';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';

export const InvalidFormat = ({
  translations,
}: {
  translations: TCryptoTransferTranslations;
}) => {
  return (
    <div className={centeredContainerStyle} data-testid="invalid-input">
      <div className={errorBackgroundStyles}>
        <Icon icon="exclamation-mark" size="24" />
      </div>
      <p className={invalidTitleStyle}>{translations.invalidFormatTitle}</p>
      <p className={invalidSubtitleStyle}>
        {translations.invalidWalletSearchMessage}
      </p>
    </div>
  );
};
