'use client';

import { useCallback, useMemo, useState } from 'react';
import {
  AmountFor,
  CreateCryptoWithdrawalOfferMutation,
  CreateExternalCryptoAddressInput,
  ExternalCryptoAddressesQuery$data,
} from '@bts-web/data-layer/server';
import {
  TAssetForTransferArray,
  TransfersDepositStepsIds,
  TransfersSendStepsIds,
  TransfersCommonStepsIds,
} from '../types';
import { useAppNotification } from '../../notifications/NotificationContextProvider';
import { TCryptoTransferTranslations } from './getCryptoTransferTranslations';
import { useTransfersAssetsListContext } from '../components/TransfersAssetListContext/TransfersAssetListContext';
import { PrecisionFloat } from '@bts-web/utils-formatting';
import { WalletAddressItem } from '../components/SendScreen/ChooseRecipientScreen/components/WalletsList';

export type PageId =
  | TransfersCommonStepsIds
  | TransfersDepositStepsIds
  | TransfersSendStepsIds;

export type PageType = {
  type: 'page';
  pageId: PageId;
};

export type TActionType =
  | PageType
  | { type: 'none'; pageId?: undefined }
  | { type: 'close'; pageId?: undefined };

export type TransferNetworkDataType = NonNullable<
  NonNullable<
    NonNullable<TAssetForTransferArray[0]['networkInformation']>['networks']
  >[0]
>;

export type TransferAssetDataType = {
  id: NonNullable<TAssetForTransferArray[0]['id']>;
  price: PrecisionFloat;
  name: NonNullable<TAssetForTransferArray[0]['name']>;
  logoUrl: NonNullable<TAssetForTransferArray[0]['logoUrl']>;
  symbol: NonNullable<TAssetForTransferArray[0]['symbol']>;
  portfolio: {
    assetBalance: PrecisionFloat;
    fiatBalance: PrecisionFloat;
  };
};

export type TransferWalletDataType = NonNullable<
  ExternalCryptoAddressesQuery$data['externalCryptoAddresses']
>[number];

export type TTransfersSectionNextStepState = {
  pageId: PageId;
  backAction: TActionType;
  closeAction: TActionType;
  selectedAssetData?: TransferAssetDataType;
  selectedNetworkData?: TransferNetworkDataType;
  selectedWalletData?: WalletAddressItem;
  addressInput: Partial<CreateExternalCryptoAddressInput> | null;
  createdWithdrawalOfferData:
    | CreateCryptoWithdrawalOfferMutation['response']['createCryptoWithdrawal']
    | null;
  assetAmountType: AmountFor;
};

export type TTransfersSectionNextStepActions = {
  bindAssetData: (
    value: TTransfersSectionNextStepState['selectedAssetData'],
  ) => void;
  bindNetworkData: (
    network: TTransfersSectionNextStepState['selectedNetworkData'],
  ) => void;
  bindWalletData: (
    walletData: TTransfersSectionNextStepState['selectedWalletData'],
  ) => void;
  changeToPageAndBindActions: (props: {
    pageId: PageId;
    newBackAction?: TActionType;
  }) => void;
  setAddressInput: (
    addressInput: Partial<CreateExternalCryptoAddressInput> | null,
  ) => void;
  setAssetAmountType: (amountType: AmountFor) => void;
  bindCreatedWithdrawalOfferData: (
    withdrawalOffer: CreateCryptoWithdrawalOfferMutation['response']['createCryptoWithdrawal'],
  ) => void;
  twoStepClose: () => void;
  isStateClean: boolean;
};

export type TTransfersNextStepStateWithActions =
  TTransfersSectionNextStepState & TTransfersSectionNextStepActions;

const initialState: TTransfersSectionNextStepState = {
  pageId: TransfersCommonStepsIds.ChooseAsset,
  backAction: { type: 'none' },
  closeAction: { type: 'close' },
  selectedAssetData: undefined,
  selectedNetworkData: undefined,
  selectedWalletData: undefined,
  createdWithdrawalOfferData: null,
  addressInput: null,
  assetAmountType: 'FIAT',
};

export const useTransfersNextStepState = ({
  closeTransferModule,
  assetList,
  translations,
}: {
  closeTransferModule: () => void;
  assetList: TAssetForTransferArray;
  translations: TCryptoTransferTranslations;
}): {
  nextStepState: TTransfersNextStepStateWithActions;
} => {
  const { clearSearchParams } = useTransfersAssetsListContext();

  const { setAppNotification } = useAppNotification();

  const [nextStepState, setNextStepState] =
    useState<TTransfersSectionNextStepState>(initialState);

  const bindAssetData = useCallback(
    (assetData: TTransfersSectionNextStepState['selectedAssetData']) => {
      const foundAsset = assetList.find((asset) => asset.id === assetData?.id);

      clearSearchParams();

      const networks = foundAsset?.networkInformation?.networks ?? [];

      if (networks?.length && networks.length === 1) {
        setAppNotification({
          title: translations.toast_info_one_network_title,
          subtitle: translations.toast_info_one_network_subtitle,
          customDuration: 3000,
          withClose: true,
        });

        setNextStepState((prevState) => {
          return {
            ...prevState,
            selectedAssetData: assetData,
            selectedNetworkData: networks[0] as TransferNetworkDataType,
            pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
            backAction: {
              pageId: TransfersCommonStepsIds.ChooseAsset,
              type: 'page',
            },
            recipientAddressId: null,
          };
        });
      } else {
        setNextStepState((prevState) => {
          const previouslySelectedNetworkId = prevState.selectedNetworkData?.id;

          let selectedNetworkData;

          if (previouslySelectedNetworkId) {
            selectedNetworkData = networks.find(
              (network) => network?.id === previouslySelectedNetworkId,
            );
          }

          return {
            ...prevState,
            selectedAssetData: assetData,
            pageId: TransfersCommonStepsIds.ChooseNetwork,
            // reset network if the previously selected one was not available, but selected
            selectedNetworkData: previouslySelectedNetworkId
              ? (selectedNetworkData as TransferNetworkDataType)
              : undefined,
            backAction: {
              pageId: TransfersCommonStepsIds.ChooseAsset,
              type: 'page',
            },
            recipientAddressId: null,
          };
        });
      }
    },
    [
      assetList,
      clearSearchParams,
      setAppNotification,
      translations.toast_info_one_network_subtitle,
      translations.toast_info_one_network_title,
    ],
  );

  const bindNetworkData = useCallback(
    (networkData: TTransfersSectionNextStepState['selectedNetworkData']) => {
      setNextStepState((prevState) => ({
        ...prevState,
        selectedNetworkData: networkData,
        pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
        backAction: {
          pageId: TransfersCommonStepsIds.ChooseNetwork,
          type: 'page',
        },
      }));
    },
    [],
  );

  const bindWalletData = useCallback(
    (walletData: TTransfersSectionNextStepState['selectedWalletData']) => {
      setNextStepState((prevState) => ({
        ...prevState,
        selectedWalletData: walletData,
      }));
    },
    [],
  );

  const changeToPageAndBindActions = useCallback(
    (props: { pageId: PageId; newBackAction?: TActionType }) => {
      const { pageId, newBackAction } = props;

      setNextStepState((prevState) => ({
        ...prevState,
        pageId,
        backAction: newBackAction ?? prevState.backAction,
      }));
    },
    [],
  );

  const setAssetAmountType = (amountType: AmountFor) => {
    setNextStepState((prevState) => ({
      ...prevState,
      assetAmountType: amountType,
    }));
  };

  const setAddressInput = (
    addressInput: Partial<CreateExternalCryptoAddressInput> | null,
  ) => {
    setNextStepState((prevState) => ({
      ...prevState,
      addressInput,
    }));
  };

  const bindCreatedWithdrawalOfferData = (
    withdrawalOfferData: TTransfersSectionNextStepState['createdWithdrawalOfferData'],
  ) => {
    setNextStepState((prevState) => ({
      ...prevState,
      createdWithdrawalOfferData: withdrawalOfferData,
    }));
  };

  const clearNextStepState = () => {
    setNextStepState(initialState);
  };

  const twoStepClose = useCallback(() => {
    if (
      [nextStepState.selectedAssetData, nextStepState.selectedNetworkData].some(
        (item) => !!item,
      )
    ) {
      clearNextStepState();
    } else {
      closeTransferModule();
    }
  }, [closeTransferModule, nextStepState]);

  const isStateClean = useMemo(() => {
    return (
      [
        nextStepState.selectedAssetData,
        nextStepState.selectedNetworkData,
      ].every((item) => item === undefined) &&
      nextStepState.pageId === TransfersCommonStepsIds.ChooseAsset
    );
  }, [
    nextStepState.pageId,
    nextStepState.selectedAssetData,
    nextStepState.selectedNetworkData,
  ]);

  return {
    nextStepState: {
      ...nextStepState,
      isStateClean,
      bindAssetData,
      bindNetworkData,
      bindWalletData,
      setAssetAmountType,
      setAddressInput,
      bindCreatedWithdrawalOfferData,
      changeToPageAndBindActions,
      twoStepClose,
    },
  };
};
