import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { HTMLRenderer } from '../../../../common/components/HTMLRenderer/HTMLRenderer';

const headerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'small',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
});

const headerIconStyles = css({
  color: 'warning.fill_primary',
  borderRadius: '29px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '64px',
  width: '64px',
  backgroundColor: 'warning.fill_secondary',
});

const headerTextStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'headline.large_semi_bold',
  fontWeight: 'headline.large_semi_bold',
  lineHeight: 'headline.large_semi_bold',
  letterSpacing: 'headline.large_semi_bold',
  textAlign: 'center',
});

export interface TransferWarningHeaderBoxProps {
  title: string;
  iconName?: string;
}

export const TransferWarningHeaderBox = ({
  title,
  iconName,
}: TransferWarningHeaderBoxProps) => {
  return (
    <div className={headerStyles} aria-label="transfer warning header">
      {iconName && (
        <Icon
          icon={iconName}
          size="32"
          theme="regular"
          data-element={`icon-${iconName}`}
          className={headerIconStyles}
        />
      )}

      <h1 aria-label="transfer warning title" className={headerTextStyles}>
        <HTMLRenderer>{title}</HTMLRenderer>
      </h1>
    </div>
  );
};
