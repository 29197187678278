import { WalletListItem, WalletListItemProps } from './WalletsListItem';
import {
  getLocale,
  i18n,
  Language,
  Locale,
  useClientTranslation,
} from '@bts-web/utils-lokalise';
import { WalletListItemSkeleton } from './WalletListItemSkeleton';
import { NewWalletAddressItem } from './NewWalletAddressItem';
import { InvalidFormat } from './InvalidFormat';
import { filterInput, isSearchValid } from './utils';
import { ErrorLoadingList } from './ErrorLoadingList';
import { useExternalCryptoAddresses } from '../../hooks/useExternalCryptoAddresses';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';
import { convertToLocaleDateString } from '@bts-web/utils-formatting';
import { TTransfersNextStepStateWithActions } from '../../../../utils/useTransfersNextStepState.client';
import {
  TransfersCommonStepsIds,
  TransfersSendStepsIds,
} from '../../../../types';

export type WalletAddressItem = Omit<
  WalletListItemProps,
  'onClick' | 'selected'
>;

interface WalletListProps {
  onChooseItem: (walletAddress: WalletAddressItem) => void;
  onCreateNewWalletAddress: (type: 'address' | 'name') => void;
  selectedItemAddress: string | null;
  searchInput: string | null;
  translations: TCryptoTransferTranslations;
  coinNetworkId?: string;
  state: TTransfersNextStepStateWithActions;
}

export const WalletsList = ({
  selectedItemAddress,
  coinNetworkId,
  searchInput,
  translations,
  onChooseItem,
  onCreateNewWalletAddress,
  state,
}: WalletListProps) => {
  const { t } = useClientTranslation();

  const currentLocale = getLocale(i18n.resolvedLanguage as Language);

  const { items, loading, error, fetchWalletLists } =
    useExternalCryptoAddresses({
      coinNetworkId,
      onEmptyList: () => {
        handleSkipRecipientStep();
      },
    });

  const handleSkipRecipientStep = () => {
    state.changeToPageAndBindActions({
      pageId: TransfersSendStepsIds.AddNewWallet,
      newBackAction: {
        pageId: TransfersCommonStepsIds.AssetAndNetworkSummary,
        type: 'page',
      },
    });
  };

  const handleTryAgain = () => {
    fetchWalletLists();
  };

  const handleCreatedOnTranslation = (
    locale: Locale,
    createdAtDate: string,
  ): string => {
    return t('created_on_date', {
      date: convertToLocaleDateString(createdAtDate, locale),
    });
  };

  let filteredItems = items ?? [];

  if (searchInput) {
    filteredItems = items.filter(filterInput(searchInput) ?? []);
  }

  if (loading) {
    return <WalletsListSkeleton />;
  }

  if (error) {
    return (
      <ErrorLoadingList
        error={error}
        onTryAgain={handleTryAgain}
        translations={translations}
      />
    );
  }

  if (!filteredItems?.length && searchInput && !isSearchValid(searchInput)) {
    return <InvalidFormat translations={translations} />;
  }

  if (!filteredItems?.length && searchInput) {
    return (
      <NewWalletAddressItem
        newWalletAddress={searchInput}
        translations={translations}
        onClick={onCreateNewWalletAddress}
      />
    );
  }

  return (
    <ul>
      {filteredItems.map((item) => (
        <li key={item.id}>
          <WalletListItem
            id={item.id}
            walletName={item.walletName}
            address={item.address}
            assetLogoUrl={item.assetLogoUrl}
            networkLogoUrl={item.networkLogoUrl}
            createdAt={item.createdAt}
            onClick={() => onChooseItem(item)}
            selected={selectedItemAddress === item.address}
            currentLocale={currentLocale}
            createdOnTranslation={handleCreatedOnTranslation}
            translations={translations}
          />
        </li>
      ))}
    </ul>
  );
};

export const WalletsListSkeleton = () => (
  <ul>
    <li>
      <WalletListItemSkeleton />
    </li>
    <li>
      <WalletListItemSkeleton />
    </li>
    <li>
      <WalletListItemSkeleton />
    </li>
    <li>
      <WalletListItemSkeleton />
    </li>
    <li>
      <WalletListItemSkeleton />
    </li>
  </ul>
);
