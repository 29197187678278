import { useCallback, useEffect, useState, useTransition } from 'react';
import { WalletAddressItem } from '../ChooseRecipientScreen/components/WalletsList';
import { mapDBToListItem } from '../ChooseRecipientScreen/components/utils';
import { getExternalCryptoAddresses } from '../actions/getRecipients.action';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { datadogErrorHelper } from '../../../../common/utils/datadogErrorHelper';

interface HookProps {
  coinNetworkId?: string;
  onEmptyList?: () => void;
}

export const useExternalCryptoAddresses = ({
  coinNetworkId,
  onEmptyList,
}: HookProps) => {
  const [items, setItems] = useState<WalletAddressItem[]>([]);

  const [isPending, startWalletListFetchingTransition] = useTransition();

  const [error, setError] = useState<string | null>(null);

  const fetchWalletLists = useCallback(async () => {
    try {
      const res = await getExternalCryptoAddresses({
        networkId: coinNetworkId,
      });

      if (res?.errors) {
        throw new Error(res?.errors.join(' | '));
      }

      const dbItems = res?.data.externalCryptoAddresses;

      if (dbItems) {
        setItems(mapDBToListItem(dbItems));

        if (!dbItems.length && onEmptyList) {
          onEmptyList();
        }
      }
    } catch (error) {
      const err = error as Error;

      setError(err.message);

      datadogErrorHelper({
        errorMessage: JSON.stringify(error),
        errorSeverity: DatadogErrorLevels.HIGH,
      });
    }
  }, [coinNetworkId]);

  useEffect(() => {
    startWalletListFetchingTransition(async () => {
      await fetchWalletLists();
    });
  }, [fetchWalletLists]);

  return {
    items,
    loading: isPending,
    error,
    fetchWalletLists,
  };
};
