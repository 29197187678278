'use client';

import { ChangeEvent, FC, useEffect, useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { ButtonBase } from '../../../../common/components/ButtonBase/ButtonBase';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { TransfersSendStepsIds } from '../../../types';
import { MainInput } from '../../../../common/components/MainInput/MainInput';
import { WalletsList, WalletAddressItem } from './components/WalletsList';
import { CreateExternalCryptoAddressInput } from '@bts-web/data-layer/server';

interface ChooseRecipientScreenProps {
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

const labelStyles = css({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
});

const stickyInputsStyle = css({
  position: 'sticky',
  top: 0,
  backgroundColor: 'screen_background.primary',
  zIndex: 1,
  paddingBottom: 'large',
  borderBottom: '1px solid',
  borderColor: 'neutrals.divider',
  px: 'medium',
  py: 'small',
  marginInlineStart: 'calc(var(--spacing-medium) * -1)',
  marginInlineEnd: 'calc(var(--spacing-medium) * -1)',
});

const inputWrapStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'small',
});

const inputStyles = css({
  maxWidth: '100%',
  padding: '12px',
  fontSize: '15px',
  border: '1px solid',
  borderColor: 'neutrals.fill_tertiary',
  borderRadius: '4px',
  marginTop: '2px',
  '&:focus': {
    marginTop: '0',
    border: '2px solid',
    borderColor: 'neutrals.stroke_primary',
  },
  '&[data-invalid=true]': {
    borderColor: 'warning.stroke_primary',
  },
  '&[data-valid=true]': {
    borderColor: 'positive.stroke_primary',
  },
});

const ChooseRecipientScreen: FC<ChooseRecipientScreenProps> = ({
  state,
  translations,
}) => {
  const { selectedNetworkData, bindWalletData } = state;

  const [walletAddressInputValue, setWalletAddressInputValue] = useState<
    string | null
  >(null);

  const [selectedWalletAddress, setSelectedWalletAddress] =
    useState<WalletAddressItem | null>(null);

  useEffect(() => {
    // Reset state
    state.setAddressInput(null);
  }, []);

  const onWalletAddressInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedNetworkData) {
      setSelectedWalletAddress(null);
    }

    const addressInputValue = e.target.value;

    const sanitizedValue = addressInputValue.replace(/[^a-zA-Z0-9_\-. ]/g, '');

    setWalletAddressInputValue(sanitizedValue);
  };

  const handleCreateNewWalletAddress = (type: 'address' | 'name') => {
    if (type === 'address') {
      state.setAddressInput({
        address: walletAddressInputValue,
      } as CreateExternalCryptoAddressInput);

      state.changeToPageAndBindActions({
        pageId: TransfersSendStepsIds.AddNewWallet,
        newBackAction: {
          pageId: TransfersSendStepsIds.ChooseRecipient,
          type: 'page',
        },
      });
    }

    if (type === 'name' && walletAddressInputValue) {
      state.setAddressInput({
        ownerLegalName: walletAddressInputValue,
      } as CreateExternalCryptoAddressInput);

      state.changeToPageAndBindActions({
        pageId: TransfersSendStepsIds.AddNewWallet,
        newBackAction: {
          pageId: TransfersSendStepsIds.ChooseRecipient,
          type: 'page',
        },
      });
    }
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
        flex: 1,
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        })}
      >
        <div className={stickyInputsStyle}>
          <div className={inputWrapStyles}>
            <label className={labelStyles} htmlFor="wallet-address">
              {translations.walletAddress}
            </label>

            <div
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
                gap: 'small',
              })}
            >
              <MainInput
                className={`${inputStyles} ${css({
                  width: '85%',
                  backgroundColor: 'white',
                })}`}
                onChange={onWalletAddressInputChange}
                value={walletAddressInputValue || ''}
                id="wallet-address"
                placeholder={translations.walletSearchPlaceholder}
              />
              <ButtonBase
                size={'small'}
                visual={'ghost'}
                noPadding
                data-testid="add-new-wallet-button"
                onClick={() => {
                  state.changeToPageAndBindActions({
                    pageId: TransfersSendStepsIds.AddNewWallet,
                    newBackAction: {
                      pageId: TransfersSendStepsIds.ChooseRecipient,
                      type: 'page',
                    },
                  });
                }}
              >
                <div
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'neutrals.fill_quinary',
                    width: '40px',
                    height: '40px',
                    borderRadius: '100px',
                    margin: '0 auto',
                  })}
                >
                  <Icon icon="add" size="16" />
                </div>
              </ButtonBase>
            </div>
          </div>
        </div>

        <WalletsList
          state={state}
          searchInput={walletAddressInputValue}
          coinNetworkId={selectedNetworkData?.coinNetworkId}
          selectedItemAddress={selectedWalletAddress?.address as string}
          onChooseItem={(walletAddress) => {
            bindWalletData(walletAddress);

            state.changeToPageAndBindActions({
              pageId: TransfersSendStepsIds.ChooseAmount,
              newBackAction: {
                pageId: TransfersSendStepsIds.ChooseRecipient,
                type: 'page',
              },
            });
          }}
          onCreateNewWalletAddress={handleCreateNewWalletAddress}
          translations={translations}
        />
      </div>
    </div>
  );
};

export { ChooseRecipientScreen };
