'use client';

import { FC, useMemo } from 'react';
import { getAppConfig } from '@bts-web/core-app-config';
import { TTransfersNextStepStateWithActions } from '../../utils/useTransfersNextStepState.client';
import { TCryptoTransferTranslations } from '../../utils/getCryptoTransferTranslations';
import { TransfersActionBar } from '../Reusable/ActionBar/TransfersActionBar';
import { AssetPickerList } from '../Reusable/AssetPickerList/AssetPickerList';
import { NetworkPickerList } from '../Reusable/NetworkPickerList/NetworkPickerList';
import { AssetAndNetworkPage } from '../Reusable/AssetAndNetworkPage/AssetAndNetworkPage';
import { TransfersLayout } from '../Reusable/TransfersLayout/TransfersLayout';
import { DepositWalletPage } from './components/DepositWalletPage/DepositWalletPage';
import { useTransfersAssetsListContext } from '../TransfersAssetListContext/TransfersAssetListContext';
import { AssetsListSkeleton } from '../TransfersParent/TransfersSkeleton';
import {
  TransfersCommonStepsIds,
  TransfersDepositStepsIds,
  TransfersSectionVariants,
} from '../../types';
import { CryptoTransfersSearchBar } from '../CryptoTransfersSearchBar/CryptoTransfersSearchBar';
import { NoResults } from '@bts-web/core-features/common';

export type DepositScreenProps = {
  state: TTransfersNextStepStateWithActions;
  transfersTranslations: TCryptoTransferTranslations;
  isReadOnlyUser: boolean;
};

const DepositScreen: FC<DepositScreenProps> = ({
  state,
  transfersTranslations,
  isReadOnlyUser,
}) => {
  const { assetsListForDeposit, isAssetsListLoading, assetFilterSearchValue } =
    useTransfersAssetsListContext();

  const {
    pageId,
    selectedAssetData,
    selectedNetworkData,
    bindAssetData,
    bindNetworkData,
  } = state;

  const { feature_showCryptoTransfersDrawerSearchBar } = getAppConfig();

  const currentNetworkList = useMemo(() => {
    if (!selectedAssetData) return null;

    const networkList = assetsListForDeposit.find(
      (item) => item.id === selectedAssetData?.id,
    )?.networkInformation?.networks;

    return networkList ?? null;
  }, [assetsListForDeposit, selectedAssetData]);

  const hasMultipleNetworks = currentNetworkList
    ? currentNetworkList?.length > 1
    : false;

  const showCryptoTransfersSearchBar =
    feature_showCryptoTransfersDrawerSearchBar && assetsListForDeposit;

  return (
    <TransfersLayout
      visual={
        TransfersDepositStepsIds.DepositWallet === pageId
          ? 'neutral'
          : 'default'
      }
      wrapperProps={{
        'aria-label': 'crypto transfers deposit flow',
      }}
      topRegion={
        <>
          <TransfersActionBar
            transfersTranslations={transfersTranslations}
            state={state}
            hasMultipleNetworks={hasMultipleNetworks}
          />

          {showCryptoTransfersSearchBar &&
          pageId === TransfersCommonStepsIds.ChooseAsset ? (
            <CryptoTransfersSearchBar
              placeholder={transfersTranslations.searchAssets}
              disabled={isReadOnlyUser}
            />
          ) : null}
        </>
      }
      middleScrollableRegion={
        isAssetsListLoading ? (
          <AssetsListSkeleton />
        ) : assetsListForDeposit && assetsListForDeposit.length > 0 ? (
          pageId === TransfersCommonStepsIds.ChooseAsset ? (
            <AssetPickerList
              onPick={bindAssetData}
              value={selectedAssetData?.id}
              variant={TransfersSectionVariants.Deposit}
            />
          ) : pageId === TransfersCommonStepsIds.ChooseNetwork &&
            currentNetworkList ? (
            <NetworkPickerList
              selectedAssetData={selectedAssetData}
              networkList={currentNetworkList}
              onNetworkSelected={bindNetworkData}
              selectedNetworkId={selectedNetworkData?.id}
              translations={transfersTranslations}
              variant={TransfersSectionVariants.Deposit}
            />
          ) : pageId === TransfersCommonStepsIds.AssetAndNetworkSummary ? (
            <AssetAndNetworkPage
              assetList={assetsListForDeposit}
              state={state}
              variant={TransfersSectionVariants.Deposit}
              translations={transfersTranslations}
            />
          ) : pageId === TransfersDepositStepsIds.DepositWallet ? (
            <DepositWalletPage
              state={state}
              translations={transfersTranslations}
            />
          ) : null
        ) : (
          <NoResults searchTerm={assetFilterSearchValue} />
        )
      }
    />
  );
};

export { DepositScreen };
