'use client';

import { useState } from 'react';
import { TransfersSectionVariants } from '../types';

export const useTransfersState = (
  transferType: TransfersSectionVariants = TransfersSectionVariants.Deposit,
) => {
  const [currentSection, setCurrentSection] =
    useState<TransfersSectionVariants>(transferType);

  return {
    currentSection,
    setCurrentSection,
  };
};
