import { SkeletonElement } from '../../../../../common';
import { detailsStyles } from './styles';
import { css } from '@bts-web/utils-style-engine';

export const WalletListItemSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      alignItems: 'center',
      py: 'medium',
      gap: 'small',
      borderBottom: '1px solid',
      borderColor: 'neutrals.divider',
      px: 'medium',
      marginInlineStart: 'calc(var(--spacing-medium) * -1)',
      marginInlineEnd: 'calc(var(--spacing-medium) * -1)',
    })}
    data-testid="wallet-item-skeleton"
  >
    <SkeletonElement width="40px" height="40px" borderRadius="40px" />

    <div className={detailsStyles}>
      <div className={css({ display: 'flex', gap: '10px' })}>
        <SkeletonElement width="80px" height="20px" />

        <SkeletonElement width="45px" height="20px" borderRadius="12px" />
      </div>

      <SkeletonElement width="190px" height="17px" />
    </div>
  </div>
);
