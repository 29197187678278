import { ExternalCryptoAddressesQuery$data } from '@bts-web/data-layer/server';
import { WalletAddressItem } from './WalletsList';

export const mapWalletResponseItemToDisplayData = (
  dbItem: NonNullable<
    ExternalCryptoAddressesQuery$data['externalCryptoAddresses']
  >[number],
): WalletAddressItem => ({
  id: dbItem?.id as string,
  createdAt: dbItem?.createdAt,
  address: dbItem?.address as string,
  walletName:
    dbItem?.ownerLegalName ||
    dbItem?.hostName ||
    `${dbItem?.asset.name} Wallet`,
  assetLogoUrl: dbItem?.asset.logoUrl as string,
  networkLogoUrl: dbItem?.network.logoUrl as string,
});

export const mapDBToListItem = (
  dbItems: ExternalCryptoAddressesQuery$data['externalCryptoAddresses'],
): WalletAddressItem[] => {
  return (
    dbItems
      ?.filter((dbItem) => !!dbItem)
      .map(mapWalletResponseItemToDisplayData) || []
  );
};

export const filterInput = (
  searchInput: string,
): ((item: WalletAddressItem) => boolean) => {
  return (item: WalletAddressItem) => {
    if (item.address.includes(searchInput)) {
      return true;
    }

    if (item.walletName.toLowerCase().includes(searchInput.toLowerCase())) {
      return true;
    }

    return false;
  };
};

export const isSearchValid = (searchInput: string): boolean => {
  if (searchInput.length > 55) {
    return false;
  }

  // Match strings bigger than 15 with repeating characters
  if (searchInput.length > 15 && /^(.)(\1)+$/.test(searchInput)) {
    return false;
  }

  return true;
};

export const shortenAddress = (address: string): string => {
  return `${address.substring(0, 4)}...${address.substring(address.length - 4, address.length)}`;
};
