'use client';

import { useEffect, useState } from 'react';
import { CreateCryptoAddressMutation } from '@bts-web/data-layer/server';
import { createCryptoAddress } from '../../../../common/gqlActions';
import { useAppNotification } from '../../../../notifications';

export const useCryptoAddress = ({
  translations: { title, subtitle },
  coinNetworkId,
}: {
  translations: {
    title: string;
    subtitle: string;
  };
  coinNetworkId?: string;
}) => {
  const [cryptoAddress, setCryptoAddress] =
    useState<CreateCryptoAddressMutation['response']['createCryptoAddress']>(
      null,
    );

  const { setAppNotification } = useAppNotification();

  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    setIsDataLoading(true);

    if (coinNetworkId) {
      createCryptoAddress(coinNetworkId)
        .then((response) => {
          setCryptoAddress(response);
        })
        .catch(() => {
          setAppNotification({
            title,
            subtitle,
            customDuration: 5000,
            withClose: true,
            visual: 'error',
            id: 'error-create-crypto-address',
          });
        })
        .finally(() => {
          setIsDataLoading(false);
        });
    }
  }, [coinNetworkId, setAppNotification, subtitle, title]);

  return { isDataLoading, cryptoAddress };
};
