import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';

const labelStyles = css({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  color: 'neutrals.text_secondary',
});

const valueStyles = css({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  color: 'neutrals.text_primary',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  minWidth: 0,
});

const captionStyles = css({
  color: 'neutrals.text_secondary',
  fontSize: 'caption.medium_medium',
  fontWeight: 'caption.medium_medium',
  lineHeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
});

interface DepositInfoFieldProps {
  label: string;
  value: string;
  caption?: string;
}

export const DepositInfoField: FC<DepositInfoFieldProps> = ({
  label,
  value,
  caption,
}) => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small_3',
      })}
    >
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
        })}
        aria-label={`deposit info ${label}`}
      >
        <span className={labelStyles}>{label}</span>
        <span className={valueStyles}>{value}</span>
      </div>

      {caption && <div className={captionStyles}>{caption}</div>}
    </div>
  );
};
