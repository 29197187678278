import { css } from '@bts-web/utils-style-engine';
import { FC, ReactNode, DetailedHTMLProps, HTMLAttributes } from 'react';

interface ITransfersLayoutProps {
  topRegion?: ReactNode;
  visual?: 'default' | 'neutral';
  middleScrollableRegion?: ReactNode;
  wrapperProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}

const TransfersLayout: FC<ITransfersLayoutProps> = ({
  topRegion,
  middleScrollableRegion,
  visual = 'default',
  wrapperProps,
}) => {
  return (
    <div
      className={css({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
        backgroundColor: 'screen_background.primary',
        ...(visual === 'default' || !visual
          ? {}
          : { backgroundColor: 'neutrals.fill_quaternary' }),
      })}
      {...wrapperProps}
    >
      {topRegion && <div className={css({})}>{topRegion}</div>}

      {middleScrollableRegion && (
        <div
          className={css({
            flex: 1,
            overflowY: 'auto',
            display: 'flex',
            height: '100%',
            flexDirection: 'column',

            ...(visual === 'default' || !visual
              ? { px: 'medium', pb: 'small' }
              : {}),
          })}
          data-testid="middle-scrollable-region"
        >
          {middleScrollableRegion}
        </div>
      )}
    </div>
  );
};

export { TransfersLayout };
