import { FC, ReactNode } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';

interface DepositWarningInfoFieldProps {
  label: ReactNode;
}

export const DepositWarningInfoField: FC<DepositWarningInfoFieldProps> = ({
  label,
}) => {
  return (
    <div
      className={css({
        gap: 'medium',
        display: 'flex',
        alignItems: 'center',
      })}
    >
      <Icon
        icon="checkmark-circle"
        size="20"
        theme="regular"
        className={css({
          color: 'neutrals.text_primary',
        })}
      />
      <div
        className={css({
          fontSize: 'body.medium',
          lineHeight: 'body.medium',
          letterSpacing: 'body.medium',
          fontWeight: 'body.medium',
          color: 'neutrals.text_primary',
          '& span:last-child': {
            fontSize: 'body.medium_medium',
            fontWeight: 'body.medium_medium',
            lineHeight: 'body.medium_medium',
            letterSpacing: 'body.medium_medium',
          },
        })}
      >
        {label}
      </div>
    </div>
  );
};
