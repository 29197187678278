import { FC, useMemo } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import {
  TransfersCommonStepsIds,
  TransfersDepositStepsIds,
  TransfersSendStepsIds,
} from '../../../types';
import { getNextPageForGoingBack } from './getNextPageForGoingBack';
import { NavigationBar } from '../../../../common';
import { useRouter, usePathname } from 'next/navigation';

export interface IActionBarProps {
  transfersTranslations: TCryptoTransferTranslations;
  state: TTransfersNextStepStateWithActions;
  hasMultipleNetworks: boolean;
}

export const TransfersActionBar: FC<IActionBarProps> = ({
  transfersTranslations,
  state,
  hasMultipleNetworks,
}) => {
  const { pageId, backAction, closeAction, selectedAssetData } = state;

  const router = useRouter();

  const pathname = usePathname();

  const buttonStyles = css({
    py: 'extra_small',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  });

  const actionBarTitle: string | null = useMemo(() => {
    const TransfersStepsTitleTranslations = {
      [TransfersCommonStepsIds.ChooseAsset]: transfersTranslations.selectAsset,
      [TransfersCommonStepsIds.ChooseNetwork]:
        transfersTranslations.selectNetwork,
      [TransfersCommonStepsIds.AssetAndNetworkSummary]:
        transfersTranslations.assetAndNetwork,
      [TransfersDepositStepsIds.DepositWallet]: `${selectedAssetData?.name} ${transfersTranslations.wallet}`,
      [TransfersSendStepsIds.ChooseAmount]: transfersTranslations.chooseAmount,
      [TransfersSendStepsIds.ChooseRecipient]:
        transfersTranslations.chooseRecipient,
      [TransfersSendStepsIds.AddNewWallet]:
        transfersTranslations.createNewWallet,
      [TransfersSendStepsIds.MemoIsMissing]: transfersTranslations.memo,
      [TransfersSendStepsIds.Confirmation]: transfersTranslations.summary,
      [TransfersSendStepsIds.TwoFactorAuth]: transfersTranslations.summary,
    };

    const translation = TransfersStepsTitleTranslations[pageId];

    return translation || null;
  }, [transfersTranslations, pageId]);

  const onBackAction = () => {
    if (backAction.type === 'page' && backAction.pageId) {
      const nextPage = getNextPageForGoingBack(
        backAction.pageId,
        hasMultipleNetworks,
      );

      if (nextPage) {
        state.changeToPageAndBindActions({
          pageId: backAction.pageId,
          newBackAction: {
            type: 'page',
            pageId: nextPage,
          },
        });
      } else {
        state.changeToPageAndBindActions({
          pageId: backAction.pageId,
          newBackAction: {
            type: 'none',
          },
        });
      }
    }

    return;
  };

  const onCloseAction = () => {
    if (pathname.includes('/crypto-transfers')) {
      router.back();

      return;
    }

    if (closeAction.type === 'page') {
      state.changeToPageAndBindActions({
        pageId: closeAction.pageId,
      });
    }

    if (closeAction.type === 'close') {
      state.twoStepClose();
    }

    return;
  };

  const isDifferentUrlForBack =
    backAction.type === 'page' && backAction.pageId !== pageId;

  const showBackButton = backAction.type === 'page' && isDifferentUrlForBack;

  return (
    <NavigationBar
      showBorderBottom={pageId !== TransfersDepositStepsIds.DepositWallet}
      title={actionBarTitle || ' '}
      NavigationLeftSlot={
        showBackButton ? (
          <button
            type="button"
            className={buttonStyles}
            aria-label="back to previous crypto transfer step button"
            onClick={onBackAction}
            style={{
              marginInlineEnd: 'auto',
            }}
          >
            <Icon icon="arrow-left" size={'24'} color="primary" />
          </button>
        ) : undefined
      }
      NavigationRightSlot={
        closeAction.type !== 'none' ? (
          <button
            aria-label="close crypto transfer button"
            className={buttonStyles}
            disabled={false}
            onClick={onCloseAction}
            style={{
              marginInlineStart: 'auto',
            }}
          >
            <Icon icon="dismiss" size={'24'} color="primary" />
          </button>
        ) : undefined
      }
    />
  );
};
