import { Icon } from '@bts-web/design-system/component/icon';
import {
  centeredContainerStyle,
  errorBackgroundStyles,
  invalidSubtitleStyle,
  invalidTitleStyle,
} from './styles';
import { ButtonBase } from '../../../../../common/components/ButtonBase/ButtonBase';
import { TCryptoTransferTranslations } from '../../../../utils/getCryptoTransferTranslations';

export const ErrorLoadingList = ({
  error,
  onTryAgain,
  translations,
}: {
  error?: string;
  onTryAgain: () => void;
  translations: TCryptoTransferTranslations;
}) => {
  return (
    <div className={centeredContainerStyle}>
      <div className={errorBackgroundStyles}>
        <Icon icon="error-x" size="24" />
      </div>
      <p className={invalidTitleStyle}>{translations.errorWalletList}</p>
      {error && <p className={invalidSubtitleStyle}>{error}</p>}

      <ButtonBase onClick={onTryAgain} size="small" visual="secondary">
        Try again
        <Icon icon="circular-arrows" size="20" />
      </ButtonBase>
    </div>
  );
};
