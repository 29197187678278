import { css } from '@bts-web/utils-style-engine';
import { ButtonBase } from '@bts-web/core-features/common';
import { TCryptoTransferTranslations } from '../../../utils/getCryptoTransferTranslations';
import { TTransfersNextStepStateWithActions } from '../../../utils/useTransfersNextStepState.client';
import { TransfersSendStepsIds } from '../../../types';
import { useTransferWarningModal } from '../hooks/useTransferWarningModal';
import { CreateExternalCryptoAddressInput } from '@bts-web/data-layer/server';

const bodyMediumTypography = css({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  lineHeight: 'body.medium',
  letterSpacing: 'body.medium',
});

const headerTextStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'headline.large_semi_bold',
  fontWeight: 'headline.large_semi_bold',
  lineHeight: 'headline.large_semi_bold',
  letterSpacing: 'headline.large_semi_bold',
});

interface MemoIsMissingScreenProps {
  state: TTransfersNextStepStateWithActions;
  translations: TCryptoTransferTranslations;
}

const MemoIsMissingScreen = ({
  state,
  translations,
}: MemoIsMissingScreenProps) => {
  const { showTransferWarningModal } = useTransferWarningModal(
    state,
    translations,
  );

  const onClickContinue = () => {
    const { addressInput } = state;

    showTransferWarningModal(addressInput as CreateExternalCryptoAddressInput);
  };

  const onClickReview = () => {
    state.changeToPageAndBindActions({
      pageId: TransfersSendStepsIds.AddNewWallet,
      newBackAction: {
        pageId: TransfersSendStepsIds.ChooseRecipient,
        type: 'page',
      },
    });
  };

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
        flex: 1,
        paddingY: 'small',
      })}
      data-testid="missing-memo-warning-screen"
    >
      <h1 aria-label="transfer warning title" className={headerTextStyles}>
        {translations.cryptoWithdrawalMemoIsMissingTitle}
      </h1>

      <p className={bodyMediumTypography}>
        {translations.cryptoWithdrawalMemoMissingText}
      </p>

      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 'extra_small',
          marginTop: 'auto',
        })}
      >
        <ButtonBase visual="primary" size="large" onClick={onClickContinue}>
          {translations.memoMissingButton}
        </ButtonBase>

        <ButtonBase visual="secondary" size="large" onClick={onClickReview}>
          {translations.cryptoWithdrawalReview}
        </ButtonBase>
      </div>
    </div>
  );
};

export { MemoIsMissingScreen };
