export enum DestinationTagType {
  MEMO_ID = 'memo_id',
  MEMO_TEXT = 'memo_text',
}

export const getMemoType = (
  memo: string,
  assetSymbol: string,
): DestinationTagType => {
  // for Stellar coin type, the destinationTag might be alphanumeric
  // source: https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/destination-tag-memo-faq
  if (assetSymbol === 'XLM') {
    return /^\d+$/.test(memo)
      ? DestinationTagType.MEMO_ID
      : DestinationTagType.MEMO_TEXT;
  }

  return DestinationTagType.MEMO_ID;
};
